import "./public-path";
import Vue from "vue";
import App from "./App.vue";
import routes from "./router";
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import store from "./store";
import Router from "vue-router";
import "./assets/icons"; // icon
import actions from "./actions";

import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");
Vue.prototype.$moment = moment;

Vue.config.productionTip = false;
Vue.use(Element, { size: "small", zIndex: 3000 });
let instance = null;

function render(props = {}) {
  const { container, routerBase } = props;
  if (props) {
    actions.setActions(props);
  }

  const router = new Router({
    // base: window.__POWERED_BY_QIANKUN__ ? 'aaa' : process.env.BASE_URL,
    // mode: 'history',
    routes
  });

  instance = new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount(container ? container.querySelector("#app") : "#app");
}

// bpmnProcessDesigner 需要引入
import MyPD from "@/components/bpmnProcessDesigner/package/index.js";
Vue.use(MyPD);
import "@/components/bpmnProcessDesigner/package/theme/index.scss";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css";

if (!window.__POWERED_BY_QIANKUN__) {
  // 这里是子应用独立运行的环境，实现子应用的登录逻辑

  // 独立运行时，也注册一个名为global的store module
  // commonStore.globalRegister(store)
  // // 模拟登录后，存储用户信息到global module
  // const userInfo = { name: '我是独立运行时名字叫张三' } // 假设登录后取到的用户信息
  // store.commit('global/setGlobalState', { user: userInfo })

  render();
}

export async function bootstrap() {
  console.log("[vue] vue app bootstraped");
}

export async function mount(props) {
  console.log("ffffffffffffffffffffffffffff", props);
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = "";
  instance = null;
}
