<script>
import draggable from "vuedraggable";
import render from "@/components/render/render";

function toChineseString(val) {
  let money = parseFloat(parseFloat(val).toFixed(4))
  var cnNums = new Array(
    "零",
    "壹",
    "贰",
    "叁",
    "肆",
    "伍",
    "陆",
    "柒",
    "捌",
    "玖"
  );
  //基本单位
  var cnIntRadice = new Array("", "拾", "佰", "仟");
  //对应整数部分扩展单位
  var cnIntUnits = new Array("", "万", "亿", "兆");
  //对应小数部分单位
  var cnDecUnits = new Array("角", "分", "毫", "厘");
  //整数金额时后面跟的字符
  var cnInteger = "整";
  //整型完以后的单位
  var cnIntLast = "元";
  //最大处理的数字
  var maxNum = 9999999999999999.9999;
  //金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  //输出的中文金额字符串
  var chineseStr = "";
  //分离金额后用的数组，预定义 
  var parts;

  if (money == "" || isNaN(money)) {
    return "";
  }
  money = parseFloat(money);
  if (money >= maxNum) {
    //超出最大处理数字
    return "大写转换最高支持十六位数的金额";
  }
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger;
    return chineseStr;
  }
  //转换为字符串
  money = money.toString();
  if (money.indexOf(".") == -1) {
    integerNum = money;
    decimalNum = "";
  } else {
    parts = money.split(".");
    integerNum = parts[0];
    decimalNum = parts[1].substr(0, 4);
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0;
    var IntLen = integerNum.length;
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1);
      var p = IntLen - i - 1;
      var q = p / 4;
      var m = p % 4;
      if (n == "0") {
        zeroCount++;
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0];
        }
        //归零
        zeroCount = 0;
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q];
      }
    }
    chineseStr += cnIntLast;
  }
  //小数部分
  if (decimalNum != "") {
    var decLen = decimalNum.length;
    for (var i = 0; i < decLen; i++) {
      var n = decimalNum.substr(i, 1);
      if (n != "0") {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
      }
    }
  }
  if (chineseStr == "") {
    chineseStr += cnNums[0] + cnIntLast + cnInteger;
  } else if (decimalNum == "") {
    chineseStr += cnInteger;
  }
  return chineseStr;

}

const group = {
  name: "componentsGroup",
  put(a, b, c, d) {
    // console.log(c.innerText.includes("实际申请人"), "|||");
    // console.log((!c.firstChild.className.includes("drawing-row-item") &&
    //   !c.innerText.includes("行容器")) && c.innerText !== '实际申请人');
    // console.log(d);
    return (
      (!c.firstChild.className.includes("drawing-row-item") &&
        !c.innerText.includes("行容器")) && !c.innerText.includes("实际申请人")
    );
    // return (
    //   (!c.firstChild.className.includes("drawing-row-item") &&
    //     !c.innerText.includes("行容器")) && !(a.el.innerText.includes("实际申请人") && c.innerText.includes("行容器"))
    // );
  },
};

const components = {
  // style={{
  //   left: currentItem.type === "default" ? '24px' : '',
  //       }}
  itemBtns(h, currentItem, index, list) {
    const { copyItem, deleteItem } = this.$listeners;
    return [
      <span
        class="drawing-item-copy"
        title="复制"
        onClick={(event) => {
          copyItem(currentItem, list);
          event.stopPropagation();
        }}
        style={currentItem.__config__._type_ ? { display: 'none' } : {}}
      >
        <i class="el-icon-copy-document" />
      </span>,
      <span
        style={currentItem.__config__._type_ ? { display: 'none' } : {}}
        class="drawing-item-delete"
        title="删除"
        onClick={(event) => {
          deleteItem(index, list);
          event.stopPropagation();
        }}
      >
        <i class="el-icon-delete" />
      </span>,
    ];
  },
};
const layouts = {
  colFormItem(h, currentItem, index, list) {
    // console.log(`output->currentItem1`, currentItem)
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    const child = renderChildren.apply(this, arguments);
    let className =
      this.activeId === config.formId
        ? "drawing-item active-from-item"
        : "drawing-item";
    if (currentItem.hideRadio) {
      className += " hideRadio"
    }
    if (this.formConf.unFocusedComponentBorder)
      className += " unfocus-bordered";
    let labelWidth = config.labelWidth ? `${config.labelWidth}px` : null;
    if (config.showLabel === false) labelWidth = "0";
    // 转换defaultValue为大写
    let upperCaseValue = config.defaultValue ? toChineseString(config.defaultValue) : '';
    className += " " + currentItem.__vModel__

    if (currentItem.__config__._type_) {
      className += " combination"
    }

    return (
      <el-col
        span={config.span}
        class={className}
        nativeOnClick={(event) => {
          activeItem(currentItem);
          event.stopPropagation();
        }}
      >
        <el-form-item
          label-width={labelWidth}
          label={config.showLabel ? config.label : ""}
          required={config.required}
        >
          {/* <render
            key={config.renderKey}
            conf={currentItem}
            onInput={(event) => {
              this.$set(config, "defaultValue", event);
            }}
          >
            {child}
          </render> */}
          <render
            key={config.renderKey}
            conf={currentItem}
          >
            {child}
          </render>
          {config.ifBig ? <div style="font-weight:bold;">大写 : <span style="font-weight:normal;">{upperCaseValue}</span></div> : null}
          {config.ifRange ? <div style="font-weight:bold;">时长 : <span style="font-weight:normal;"></span></div> : null}
        </el-form-item>
        {components.itemBtns.apply(this, arguments)}
      </el-col>
    );
  },
  rowFormItem(h, currentItem, index, list) {
    const { activeItem } = this.$listeners;
    const config = currentItem.__config__;
    const className =
      this.activeId === config.formId
        ? "drawing-row-item active-from-item"
        : "drawing-row-item";
    let child = renderChildren.apply(this, arguments);
    if (currentItem.type === "flex") {
      child = (
        <el-row
          type={currentItem.type}
          justify={currentItem.justify}
          align={currentItem.align}
          class="default"
        >
          {child}
        </el-row>
      );
    }

    return (
      <el-col span={config.span}>
        <el-row
          style="padding-top: 22px;"
          gutter={config.gutter}
          class={className}
          nativeOnClick={(event) => {
            activeItem(currentItem);
            event.stopPropagation();
          }}
        >
          <span class="component-name">{config.form.标题}</span>
          <draggable
            list={config.children[0] || []}
            animation={340}
            group={group}
            class="drag-wrapper"
            filter={config.form.type ? ".combination" : ""}
          >
            {child}
          </draggable>
          {components.itemBtns.apply(this, arguments)}
          {/* <el-button
            type="text"
            disabled
            icon="el-icon-plus"
            style="width: 100%;cursor:pointer"
          >
            {config.form.动作名称}
          </el-button> */}
        </el-row>
      </el-col>
    );
  },
  raw(h, currentItem, index, list) {
    const config = currentItem.__config__;
    const child = renderChildren.apply(this, arguments);
    return (
      <render
        key={config.renderKey}
        conf={currentItem}
        onInput={(event) => {
          this.$set(config, "defaultValue", event);
        }}
      >
        {child}
      </render>
    );
  },
};

function renderChildren(h, currentItem, index, list) {
  const config = currentItem.__config__;
  if (!Array.isArray(config.children) || !Array.isArray(config.children[0]))
    return null;
  return config.children[0].map((el, i) => {
    const layout = layouts[el.__config__.layout];
    if (layout) {
      return layout.call(this, h, el, i, config.children[0]);
    }
    return layoutIsNotFound.call(this);
  });
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.currentItem.__config__.layout}匹配的layout`);
}

export default {
  components: {
    render,
    draggable,
  },
  props: ["currentItem", "index", "drawingList", "activeId", "formConf"],
  methods: {
    // // 拖拽开始事件
    // handleDragStart(e, arr) {

    //   //    
    //   // console.log(`output->arr`, e, arr)
    //   // {/* filter={config.form.type ? '.drag-handle' : ''} */}
    // },

  },
  render(h) {
    const layout = layouts[this.currentItem.__config__.layout];

    if (layout) {
      return layout.call(
        this,
        h,
        this.currentItem,
        this.index,
        this.drawingList
      );
    }
    return layoutIsNotFound.call(this);
  },
};
</script>
