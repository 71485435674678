var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Usergroup" },
    [
      _c("div", { staticClass: "leftcard" }, [
        _vm._m(0),
        _c(
          "div",
          { staticStyle: { "margin-top": "16px", "margin-left": "12px" } },
          [
            _c(
              "div",
              {
                staticClass: "leftBtn activeLeftBtn",
                on: {
                  click: function($event) {
                    return _vm.switchRouter(1)
                  }
                }
              },
              [_vm._v("用户组管理")]
            ),
            _c(
              "div",
              {
                staticClass: "leftBtn",
                on: {
                  click: function($event) {
                    return _vm.switchRouter(2)
                  }
                }
              },
              [_vm._v("流程管理")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "middlecard" }, [
        _c("div", { staticClass: "midTop" }, [
          _c("div", { staticClass: "inputDiv" }, [
            _c("i", { staticClass: "el-icon-search" }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchValue,
                  expression: "searchValue"
                }
              ],
              attrs: { type: "text", placeholder: "搜索用户群组/用户组" },
              domProps: { value: _vm.searchValue },
              on: {
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.searchFlow($event)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.searchValue = $event.target.value
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "midmin" }, [
          _c(
            "div",
            {
              staticClass: "minbtn",
              staticStyle: { width: "92px", "margin-right": "8px" },
              on: {
                click: function($event) {
                  return _vm.addUserGroupBtn(1)
                }
              }
            },
            [_vm._v("新增用户群组")]
          ),
          _c(
            "div",
            {
              staticClass: "minbtn",
              staticStyle: { width: "80px" },
              on: {
                click: function($event) {
                  return _vm.addUserGroupBtn(2)
                }
              }
            },
            [_vm._v("新增用户组")]
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "16px",
                height: "16px",
                "margin-left": "auto",
                cursor: "pointer"
              },
              on: { click: _vm.takExport }
            },
            [
              _c("img", {
                attrs: {
                  src: require("@/assets/upload.png"),
                  alt: "",
                  width: "100%",
                  height: "100%"
                }
              })
            ]
          )
        ]),
        _c(
          "div",
          { staticClass: "midList" },
          [
            _c("el-tree", {
              ref: "tree",
              attrs: {
                data: _vm.treeData,
                "node-key": "id",
                props: _vm.defaultProps,
                "default-expand-all": "",
                "filter-node-method": _vm.filterNode
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var node = ref.node
                    var data = ref.data
                    return _c(
                      "span",
                      {
                        staticClass: "custom-tree-node",
                        on: {
                          click: function($event) {
                            return _vm.getUserList(data)
                          }
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center"
                            }
                          },
                          [
                            node.level == 2
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/user.png"),
                                    alt: "",
                                    width: "15px",
                                    height: "15px"
                                  }
                                })
                              : _vm._e(),
                            node.level == 1 && node.expanded
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/Component.png"),
                                    alt: "",
                                    width: "15px",
                                    height: "15px"
                                  }
                                })
                              : _vm._e(),
                            node.level == 1 && !node.expanded
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/Component1.png"),
                                    alt: "",
                                    width: "15px",
                                    height: "15px"
                                  }
                                })
                              : _vm._e(),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "6px" } },
                              [_vm._v(_vm._s(node.label))]
                            )
                          ]
                        ),
                        data.parentId == -1
                          ? _c(
                              "span",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-right": "6px" },
                                    attrs: { type: "text", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return (function() {
                                          return _vm.editCluster(data)
                                        })($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" 编辑 ")]
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  }
                }
              ])
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "maincard" }, [
        _c("div", { staticClass: "topL" }, [
          _c(
            "div",
            {
              staticStyle: {
                "font-weight": "600",
                "font-size": "14px",
                color: "#1b1b1b",
                "line-height": "14px",
                "margin-right": "8px"
              }
            },
            [_vm._v(_vm._s(_vm.groupData.name || "--"))]
          ),
          _vm.groupData.name
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "40px",
                    height: "20px",
                    "line-height": "20px",
                    "background-color": "#f3f4f6",
                    "border-radius": "20px",
                    color: "#848484",
                    "font-size": "12px",
                    cursor: "pointer"
                  },
                  on: { click: _vm.systemBtn }
                },
                [_vm._v(" 设置")]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "tops" }, [
          _c(
            "div",
            {
              staticClass: "addBtn",
              staticStyle: { "background-color": "#5669ec", color: "#fff" },
              on: { click: _vm.addUser }
            },
            [
              _c("i", {
                staticClass: "el-icon-plus ml-3",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("添加")
            ]
          ),
          _c(
            "div",
            {
              staticClass: "addBtn",
              staticStyle: { "background-color": "#f3f4f6", color: "#848484" },
              on: { click: _vm.deleteUser }
            },
            [
              _c("i", {
                staticClass: "el-icon-delete ml-3",
                staticStyle: { "margin-right": "2px" }
              }),
              _vm._v("删除")
            ]
          )
        ]),
        _c("div", { staticClass: "bootn" }, [
          _c(
            "div",
            { staticClass: "culm" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "flex-shrink": "0",
                    width: "22px",
                    "align-items": "center",
                    display: "flex",
                    "justify-content": "end"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ischeckBox",
                      on: { click: _vm.checkedAll }
                    },
                    [
                      _vm.isChecked
                        ? _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: require("@/assets/check2.png"),
                              alt: ""
                            }
                          })
                        : _c("img", {
                            staticStyle: { width: "100%", height: "100%" },
                            attrs: {
                              src: require("@/assets/check1.png"),
                              alt: ""
                            }
                          })
                    ]
                  )
                ]
              ),
              _c(
                "el-row",
                { staticStyle: { flex: "1" } },
                [
                  _c(
                    "el-col",
                    { staticClass: "cumItem", attrs: { span: 10 } },
                    [_vm._v("名称")]
                  ),
                  _c(
                    "el-col",
                    { staticClass: "cumItem", attrs: { span: 11 } },
                    [_vm._v("管理范围")]
                  ),
                  _c("el-col", { staticClass: "cumItem", attrs: { span: 3 } }, [
                    _vm._v("操作")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "clist" },
            _vm._l(_vm.tableData, function(item, index) {
              return _c("div", { attrs: { index: item.id } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      position: "relative"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "flex-shrink": "0",
                          width: "22px",
                          "align-items": "center",
                          display: "flex",
                          "justify-content": "end"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "ischeckBox",
                            on: {
                              click: function($event) {
                                return _vm.checkItem(item)
                              }
                            }
                          },
                          [
                            item.check
                              ? _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: require("@/assets/check4.png"),
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  staticStyle: {
                                    width: "100%",
                                    height: "100%"
                                  },
                                  attrs: {
                                    src: require("@/assets/check3.png"),
                                    alt: ""
                                  }
                                })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "el-row",
                      { staticClass: "cl" },
                      [
                        _c(
                          "el-col",
                          {
                            staticClass: "cumItem flex items-center",
                            attrs: { span: 10 }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "border-radius": "50%",
                                  width: "28px",
                                  height: "28px",
                                  margin: "0 8px 0 12px",
                                  "flex-shrink": "0",
                                  overflow: "hidden"
                                }
                              },
                              [
                                item.avatarUrl
                                  ? _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: { src: item.avatarUrl, alt: "" }
                                    })
                                  : _c("img", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src:
                                          "https://cdn.fengwork.com/workbench/20240118touxiang.png",
                                        alt: ""
                                      }
                                    })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "clText",
                                staticStyle: {
                                  width: "calc(100% - 48px)",
                                  "word-break": "break-all",
                                  "text-align": "left"
                                }
                              },
                              [_vm._v(" " + _vm._s(item.name))]
                            )
                          ]
                        ),
                        _c("el-col", { attrs: { span: 11 } }, [
                          item.scope.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "clText",
                                  staticStyle: {
                                    width: "100%",
                                    "word-break": "break-all"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        item.scope
                                          .map(function(e) {
                                            return e.label
                                          })
                                          .join(",")
                                      )
                                  )
                                ]
                              )
                            : _c("div", { staticClass: "clText" }, [
                                _vm._v("所有人")
                              ])
                        ]),
                        _c("el-col", { attrs: { span: 3 } }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                width: "16px",
                                height: "16px",
                                margin: "0 auto"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setScope(item)
                                }
                              }
                            },
                            [
                              _c("img", {
                                attrs: {
                                  src: require("@/assets/settings.png"),
                                  alt: ""
                                }
                              })
                            ]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("div", { staticClass: "line" })
              ])
            }),
            0
          )
        ])
      ]),
      _c(
        "Customdrawer",
        {
          ref: "customDrawer",
          attrs: { title: _vm.drawerMain.title },
          on: {
            closeDrawer: _vm.closeDrawer,
            submitDrawer: _vm.submitDrawer,
            deleteDrawer: _vm.deleteDrawer
          }
        },
        [
          _c("template", { slot: "drawerMain" }, [
            _c("div", { staticClass: "addGroupDrawer" }, [
              _c(
                "div",
                {
                  staticStyle: {
                    "text-align": "left",
                    "font-size": "13px",
                    color: "#1b1b1b",
                    "font-weight": "500",
                    "margin-top": "16px"
                  }
                },
                [_vm._v(" " + _vm._s(_vm.drawerMain.drawerMainText))]
              ),
              _c("div", [
                _vm.drawerMain.type == 1 || _vm.drawerMain.type == 4
                  ? _c("div", [
                      _c("div", { staticClass: "drawerMainLabel" }, [
                        _c("div", { staticClass: "cot" }),
                        _vm._v("用户群组名称 ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "drawerMainInput" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入用户群组名称" },
                            model: {
                              value: _vm.formLabelAlign.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formLabelAlign, "name", $$v)
                              },
                              expression: "formLabelAlign.name"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm.drawerMain.type == 2 || _vm.drawerMain.type == 5
                  ? _c("div", [
                      _c("div", { staticClass: "drawerMainLabel" }, [
                        _c("div", { staticClass: "cot" }),
                        _vm._v("用户组名称 ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "drawerMainInput" },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入用户组名称" },
                            model: {
                              value: _vm.formLabelAlign.name,
                              callback: function($$v) {
                                _vm.$set(_vm.formLabelAlign, "name", $$v)
                              },
                              expression: "formLabelAlign.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "drawerMainLabel" }, [
                        _c("div", { staticClass: "cot" }),
                        _vm._v("分组到 ")
                      ]),
                      _c(
                        "div",
                        { staticClass: "drawerMainInput" },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择分组",
                                "popper-append-to-body": false
                              },
                              model: {
                                value: _vm.formLabelAlign.parentId,
                                callback: function($$v) {
                                  _vm.$set(_vm.formLabelAlign, "parentId", $$v)
                                },
                                expression: "formLabelAlign.parentId"
                              }
                            },
                            _vm._l(_vm.treeData, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]),
              _vm.drawerMain.type == 5
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "text-align": "left",
                            "font-size": "13px",
                            color: "#1b1b1b",
                            "font-weight": "500",
                            "margin-top": "23px",
                            "border-top": "1px dashed #e4e5e7",
                            "padding-top": "24px"
                          }
                        },
                        [_vm._v(" 关联流程")]
                      ),
                      _vm._l(_vm.flowList, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "dsf",
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                              "margin-top": "16px"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "28px",
                                  height: "28px",
                                  "border-radius": "50%",
                                  overflow: "hidden",
                                  "margin-right": "8px"
                                }
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: item.flowUrl,
                                    alt: "",
                                    width: "28px",
                                    height: "28px"
                                  }
                                })
                              ]
                            ),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "13px",
                                  color: "#1b1b1b"
                                }
                              },
                              [_vm._v(_vm._s(item.flowName))]
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ])
        ],
        2
      ),
      _c("initiatingRange", {
        ref: "initiatingRange",
        attrs: {
          title: _vm.intitle,
          selectedList: _vm.chooseList,
          addType: "0",
          isGroup: _vm.isGroup,
          required: false
        },
        on: { confirm: _vm.getChooseList }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "flex items-center",
        staticStyle: { "margin-left": "20px", "margin-top": "17px" }
      },
      [
        _c("img", {
          attrs: {
            src: "https://cdn.fengwork.com/workbench/Group_101.png",
            alt: "",
            width: "22px",
            height: "24px"
          }
        }),
        _c(
          "span",
          {
            staticStyle: {
              "margin-left": "5px",
              "font-size": "13px",
              color: "#1b1b1b",
              "font-weight": "550"
            }
          },
          [_vm._v("管理后台")]
        )
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }