var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "panel-tab__content" },
    [
      _c(
        "el-form",
        {
          attrs: {
            size: "mini",
            "label-position": "top",
            model: _vm.model,
            rules: _vm.rules
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _vm.elementBaseInfo.$type === "bpmn:Process"
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程标识", prop: "key" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入流标标识", readonly: "" },
                        on: { change: _vm.handleKeyUpdate },
                        model: {
                          value: _vm.model.key,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "key", $$v)
                          },
                          expression: "model.key"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "流程名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入流程名称",
                          readonly: "",
                          clearable: ""
                        },
                        on: { change: _vm.handleNameUpdate },
                        model: {
                          value: _vm.model.name,
                          callback: function($$v) {
                            _vm.$set(_vm.model, "name", $$v)
                          },
                          expression: "model.name"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "ID" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.updateBaseInfo("id")
                          }
                        },
                        model: {
                          value: _vm.elementBaseInfo.id,
                          callback: function($$v) {
                            _vm.$set(_vm.elementBaseInfo, "id", $$v)
                          },
                          expression: "elementBaseInfo.id"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        on: {
                          change: function($event) {
                            return _vm.updateBaseInfo("name")
                          }
                        },
                        model: {
                          value: _vm.elementBaseInfo.name,
                          callback: function($$v) {
                            _vm.$set(_vm.elementBaseInfo, "name", $$v)
                          },
                          expression: "elementBaseInfo.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.elementBaseInfo.$type == "bpmn:ServiceTask"
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "抄送人员", prop: "userIds" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "select_box",
                                staticStyle: { "margin-bottom": "8px" }
                              },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "info", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addUser(98)
                                      }
                                    }
                                  },
                                  [_vm._v(" 添加人员 ")]
                                ),
                                _vm._l(
                                  _vm.elementBaseInfo.options.user,
                                  function(item, index) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: item.value,
                                        attrs: {
                                          type: "info",
                                          size: "mini",
                                          closable: ""
                                        },
                                        on: {
                                          close: function($event) {
                                            return _vm.closeUser(index, 98)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }
                                )
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "select_box",
                                staticStyle: { "margin-bottom": "8px" }
                              },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "info", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addUser(97)
                                      }
                                    }
                                  },
                                  [_vm._v(" 添加用户组 ")]
                                ),
                                _vm._l(
                                  _vm.elementBaseInfo.options.role,
                                  function(item, index) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: item.value,
                                        attrs: {
                                          type: "info",
                                          size: "mini",
                                          closable: ""
                                        },
                                        on: {
                                          close: function($event) {
                                            return _vm.closeUser(index, 97)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }
                                )
                              ],
                              2
                            ),
                            _c(
                              "div",
                              { staticClass: "select_box" },
                              [
                                _c(
                                  "el-tag",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "info", size: "mini" },
                                    on: {
                                      click: function($event) {
                                        return _vm.addUser(96)
                                      }
                                    }
                                  },
                                  [_vm._v(" 添加主管 ")]
                                ),
                                _vm._l(
                                  _vm.elementBaseInfo.options.dept,
                                  function(item, index) {
                                    return _c(
                                      "el-tag",
                                      {
                                        key: item.value,
                                        attrs: {
                                          type: "info",
                                          size: "mini",
                                          closable: ""
                                        },
                                        on: {
                                          close: function($event) {
                                            return _vm.closeUser(index, 96)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.label) + " ")]
                                    )
                                  }
                                )
                              ],
                              2
                            )
                          ]
                        )
                      ]
                    : _vm._e(),
                  _vm.elementBaseInfo.$type == "bpmn:UserTask"
                    ? [
                        _c(
                          "el-form-item",
                          { attrs: { label: "指派类型" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "100%" },
                                attrs: { clearable: "" },
                                on: {
                                  change: function($event) {
                                    return _vm.handleUpdate("type")
                                  }
                                },
                                model: {
                                  value: _vm.elementBaseInfo.type,
                                  callback: function($$v) {
                                    _vm.$set(_vm.elementBaseInfo, "type", $$v)
                                  },
                                  expression: "elementBaseInfo.type"
                                }
                              },
                              _vm._l(_vm.taskAssignRuleTypeDictDatas, function(
                                dict
                              ) {
                                return _c("el-option", {
                                  key: parseInt(dict.value),
                                  attrs: {
                                    label: dict.label,
                                    value: parseInt(dict.value),
                                    disabled:
                                      dict.value == 80 && _vm.cascaderFlag
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _vm.elementBaseInfo.type === 30
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "指派人员", prop: "userIds" }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "select_box" },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { type: "info", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addUser(30)
                                            }
                                          }
                                        },
                                        [_vm._v(" 添加 ")]
                                      ),
                                      _vm._l(
                                        _vm.elementBaseInfo.options.userList,
                                        function(item, index) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: item.userId,
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                                closable: ""
                                              },
                                              on: {
                                                close: function($event) {
                                                  return _vm.closeUser(
                                                    index,
                                                    30
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 70
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发起人自选" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.schema,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "schema",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.schema"
                                      }
                                    },
                                    _vm._l(
                                      _vm.$store.state.bpm.schemaOption,
                                      function(item) {
                                        return _c("el-option", {
                                          key: parseInt(item.value),
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: { change: _vm.changeScope },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.scope,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "scope",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.scope"
                                      }
                                    },
                                    _vm._l(
                                      _vm.$store.state.bpm.scopeOption,
                                      function(item) {
                                        return _c("el-option", {
                                          key: parseInt(item.value),
                                          attrs: {
                                            label: item.label,
                                            value: item.value
                                          }
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.elementBaseInfo.options.scope != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "select_box" },
                                        [
                                          _c(
                                            "el-tag",
                                            {
                                              staticStyle: {
                                                cursor: "pointer"
                                              },
                                              attrs: {
                                                type: "info",
                                                size: "mini"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addUser(70)
                                                }
                                              }
                                            },
                                            [_vm._v(" 添加 ")]
                                          ),
                                          _vm._l(
                                            _vm.elementBaseInfo.options
                                              .candidates,
                                            function(item, index) {
                                              return _c(
                                                "el-tag",
                                                {
                                                  key: item.userId,
                                                  attrs: {
                                                    type: "info",
                                                    size: "mini",
                                                    closable: ""
                                                  },
                                                  on: {
                                                    close: function($event) {
                                                      return _vm.closeUser(
                                                        index,
                                                        70
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(item.label) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 40
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "用户组" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "select_box" },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { type: "info", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addUser(40)
                                            }
                                          }
                                        },
                                        [_vm._v(" 添加 ")]
                                      ),
                                      _vm._l(
                                        _vm.elementBaseInfo.options.roles,
                                        function(item, index) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: item.userId,
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                                closable: ""
                                              },
                                              on: {
                                                close: function($event) {
                                                  return _vm.closeUser(
                                                    index,
                                                    40
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                ]
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 21
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发起人的" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.level,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "level",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.level"
                                      }
                                    },
                                    _vm._l(_vm.levelOption, function(item) {
                                      return _c("el-option", {
                                        key: parseInt(item.value),
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 22
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "发起人的" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.level,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "level",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.level"
                                      }
                                    },
                                    _vm._l(_vm.levelOption, function(item) {
                                      return _c("el-option", {
                                        key: parseInt(item.value),
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-checkbox",
                                    {
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.alternate,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "alternate",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.alternate"
                                      }
                                    },
                                    [_vm._v("找不到主管时，由上级主管代审批")]
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 23
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "通讯录中的" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.level,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "level",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.level"
                                      }
                                    },
                                    _vm._l(_vm.continuousOption, function(
                                      item
                                    ) {
                                      return _c("el-option", {
                                        key: parseInt(item.value),
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 80
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "表单联系人" } },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-bottom": "8px"
                                      },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options.contact,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "contact",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.contact"
                                      }
                                    },
                                    _vm._l(_vm.cascaderOption, function(item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value
                                        }
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _vm.isSingle
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "审批方式" } },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    on: { change: _vm.changeApproveType },
                                    model: {
                                      value:
                                        _vm.elementBaseInfo.options.approveType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.elementBaseInfo.options,
                                          "approveType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "elementBaseInfo.options.approveType"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio",
                                      { attrs: { label: "sequence" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "wrap",
                                              "word-break": "break-all",
                                              "font-size": "12px"
                                            }
                                          },
                                          [_vm._v("依次审批(按顺序同意或拒绝)")]
                                        )
                                      ]
                                    ),
                                    _vm.elementBaseInfo.type != 23
                                      ? _c(
                                          "el-radio",
                                          { attrs: { label: "and" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "white-space": "wrap",
                                                  "word-break": "break-all",
                                                  "font-size": "12px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "会签(需要所有审批人都同意才可通过)"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.elementBaseInfo.type != 23
                                      ? _c(
                                          "el-radio",
                                          { attrs: { label: "or" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "white-space": "wrap",
                                                  "word-break": "break-all",
                                                  "font-size": "12px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "或签(其中一名审批人同意或拒绝即可)"
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isReserved
                          ? [
                              _c(
                                "el-form-item",
                                { attrs: { label: "审批人空时" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      staticStyle: { "margin-bottom": "8px" },
                                      on: {
                                        change: function($event) {
                                          return _vm.handleUpdate("options")
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.elementBaseInfo.options
                                            .reservedType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.elementBaseInfo.options,
                                            "reservedType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "elementBaseInfo.options.reservedType"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "pass" } },
                                        [_vm._v("自动通过")]
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "transfer" } },
                                        [
                                          _vm._v("自动转交管理员"),
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: { placement: "top" }
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: { slot: "content" },
                                                  slot: "content"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                        "background-color":
                                                          "#f3f4f6",
                                                        padding: "2px 4px",
                                                        "border-radius": "4px",
                                                        border:
                                                          "1px solid #848484"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "当前审批未找到审批人，转交给流程管理员"
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("i", {
                                                staticClass: "el-icon-info"
                                              })
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-radio",
                                        { attrs: { label: "assign" } },
                                        [_vm._v(" 指定人员审批")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.elementBaseInfo.options.reservedType ==
                              "assign"
                                ? _c(
                                    "div",
                                    { staticClass: "select_box" },
                                    [
                                      _c(
                                        "el-tag",
                                        {
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { type: "info", size: "mini" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addUser(99)
                                            }
                                          }
                                        },
                                        [_vm._v(" 添加 ")]
                                      ),
                                      _vm._l(
                                        _vm.elementBaseInfo.options
                                          .reservedUser,
                                        function(item, index) {
                                          return _c(
                                            "el-tag",
                                            {
                                              key: item.userId,
                                              attrs: {
                                                type: "info",
                                                size: "mini",
                                                closable: ""
                                              },
                                              on: {
                                                close: function($event) {
                                                  return _vm.closeUser(
                                                    index,
                                                    99
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.label) + " "
                                              )
                                            ]
                                          )
                                        }
                                      )
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm.elementBaseInfo.type === 10
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "指派人员", prop: "options" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { multiple: "", clearable: "" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleUpdate("options")
                                      }
                                    },
                                    model: {
                                      value: _vm.elementBaseInfo.options,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.elementBaseInfo,
                                          "options",
                                          $$v
                                        )
                                      },
                                      expression: "elementBaseInfo.options"
                                    }
                                  },
                                  _vm._l(
                                    _vm.$store.state.bpm.roleOptions,
                                    function(item) {
                                      return _c("el-option", {
                                        key: parseInt(item.roleId),
                                        attrs: {
                                          label: item.roleName,
                                          value: item.roleId
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        false
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "指派人员", prop: "deptIds" } },
                              [
                                _c("treeselect", {
                                  attrs: {
                                    options:
                                      _vm.$store.state.bpm.deptTreeOptions,
                                    multiple: "",
                                    flat: "",
                                    defaultExpandLevel: 3,
                                    placeholder: "请选择",
                                    normalizer: _vm.normalizer
                                  },
                                  on: {
                                    input: function($event) {
                                      return _vm.handleUpdate("options")
                                    }
                                  },
                                  model: {
                                    value: _vm.elementBaseInfo.options,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.elementBaseInfo,
                                        "options",
                                        $$v
                                      )
                                    },
                                    expression: "elementBaseInfo.options"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        false
                          ? _c(
                              "el-form-item",
                              { attrs: { label: "指派人员", prop: "userIds" } },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      multiple: "",
                                      clearable: "",
                                      filterable: ""
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleUpdate("options")
                                      }
                                    },
                                    model: {
                                      value: _vm.elementBaseInfo.options,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.elementBaseInfo,
                                          "options",
                                          $$v
                                        )
                                      },
                                      expression: "elementBaseInfo.options"
                                    }
                                  },
                                  _vm._l(
                                    _vm.$store.state.bpm.userOptions,
                                    function(item) {
                                      return _c("el-option", {
                                        key: item.userId,
                                        attrs: {
                                          label: item.userName,
                                          value: item.userId
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        false
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "指派人员",
                                  prop: "userGroupIds"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "100%" },
                                    attrs: { multiple: "", clearable: "" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleUpdate("options")
                                      }
                                    },
                                    model: {
                                      value: _vm.elementBaseInfo.options,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.elementBaseInfo,
                                          "options",
                                          $$v
                                        )
                                      },
                                      expression: "elementBaseInfo.options"
                                    }
                                  },
                                  _vm._l(
                                    _vm.$store.state.bpm.userGroupOptions,
                                    function(item) {
                                      return _c("el-option", {
                                        key: item.id,
                                        attrs: {
                                          label: item.name,
                                          value: item.id
                                        }
                                      })
                                    }
                                  ),
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
        ]
      ),
      _c("initiatingRange", {
        ref: "initiatingRange",
        attrs: {
          title: _vm.title,
          isUser: true,
          addType: _vm.addType,
          selectedList: _vm.selectedList,
          radio: _vm.radio
        },
        on: { confirm: _vm.confirm }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }