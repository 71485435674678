var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-board" },
    [
      _c(
        "el-tabs",
        {
          staticClass: "center-tabs",
          model: {
            value: _vm.currentTab,
            callback: function($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab"
          }
        },
        [
          _c("el-tab-pane", { attrs: { name: "field" } }, [
            _c(
              "div",
              {
                staticStyle: { padding: "0 8px" },
                attrs: { slot: "label" },
                slot: "label"
              },
              [_vm._v("组件属性")]
            )
          ]),
          _c("el-tab-pane", { attrs: { name: "form" } }, [
            _c(
              "div",
              {
                staticStyle: { padding: "0 8px" },
                attrs: { slot: "label" },
                slot: "label"
              },
              [_vm._v("表单属性")]
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "field-box" },
        [
          _c(
            "el-scrollbar",
            {
              staticClass: "right-scrollbar",
              staticStyle: { padding: "0 4px 0 8px" }
            },
            [
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "field" && _vm.showField,
                      expression: "currentTab === 'field' && showField"
                    }
                  ],
                  ref: "dynamicValidateForm",
                  attrs: {
                    model: _vm.activeData,
                    size: "small",
                    "label-position": "top",
                    disabled: _vm.activeData.__config__._type_ ? true : false
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.activeData.__config__.layoutTree
                        ? {
                            key: "default",
                            fn: function(ref) {
                              var node = ref.node
                              var data = ref.data
                              return [
                                _vm.activeData.__config__.tag === "el-row"
                                  ? _c(
                                      "el-form",
                                      {
                                        ref: "form",
                                        attrs: {
                                          model: _vm.activeData.__config__.form,
                                          "label-position": "top"
                                        }
                                      },
                                      [
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "标题" } },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value:
                                                  _vm.activeData.__config__.form
                                                    .标题,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.activeData.__config__
                                                      .form,
                                                    "标题",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "activeData.__config__.form.标题"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "动作名称" } },
                                          [
                                            _c("el-input", {
                                              model: {
                                                value:
                                                  _vm.activeData.__config__.form
                                                    .动作名称,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.activeData.__config__
                                                      .form,
                                                    "动作名称",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "activeData.__config__.form.动作名称"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "el-form-item",
                                          { attrs: { label: "填写方式" } },
                                          [
                                            _c(
                                              "el-radio-group",
                                              {
                                                model: {
                                                  value:
                                                    _vm.activeData.__config__
                                                      .form.填写方式,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.activeData.__config__
                                                        .form,
                                                      "填写方式",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "activeData.__config__.form.填写方式"
                                                }
                                              },
                                              [
                                                _c("el-radio-button", {
                                                  attrs: { label: "列表" }
                                                }),
                                                _c("el-radio-button", {
                                                  attrs: { label: "表格" }
                                                })
                                              ],
                                              1
                                            ),
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.activeData.__config__
                                                      .form.填写方式 === "表格",
                                                  expression:
                                                    "activeData.__config__.form.填写方式 === '表格'"
                                                }
                                              ],
                                              staticStyle: {
                                                width: "100%",
                                                "margin-top": "10px"
                                              },
                                              attrs: {
                                                src: require("@/assets/Group1000004938.png"),
                                                alt: ""
                                              }
                                            }),
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.activeData.__config__
                                                      .form.填写方式 === "列表",
                                                  expression:
                                                    "activeData.__config__.form.填写方式 === '列表'"
                                                }
                                              ],
                                              staticStyle: {
                                                width: "100%",
                                                "margin-top": "10px"
                                              },
                                              attrs: {
                                                src: require("@/assets/Group1000004939.png"),
                                                alt: ""
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        : null
                    ],
                    null,
                    true
                  )
                },
                [
                  _vm.activeData.__config__.changeTag
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: {
                                placeholder: "请选择组件类型",
                                disabled: ""
                              },
                              on: { change: _vm.tagChange },
                              model: {
                                value: _vm.activeData.__config__.tagIcon,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.activeData.__config__,
                                    "tagIcon",
                                    $$v
                                  )
                                },
                                expression: "activeData.__config__.tagIcon"
                              }
                            },
                            _vm._l(_vm.tagList, function(group) {
                              return _c(
                                "el-option-group",
                                {
                                  key: group.label,
                                  attrs: { label: group.label }
                                },
                                _vm._l(group.options, function(item) {
                                  return _c(
                                    "el-option",
                                    {
                                      key: item.__config__.label,
                                      attrs: {
                                        label: item.__config__.label,
                                        value: item.__config__.tagIcon
                                      }
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass: "node-icon",
                                        attrs: {
                                          "icon-class": item.__config__.tagIcon
                                        }
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.__config__.label)
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__vModel__ !== undefined &&
                  _vm.activeData.__config__.type !== "litigant"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "字段名" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入字段名（v-model）" },
                            model: {
                              value: _vm.activeData.__vModel__,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "__vModel__", $$v)
                              },
                              expression: "activeData.__vModel__"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.标题 !== undefined
                    ? _c("el-form-item", { attrs: { label: "组件名" } }, [
                        _vm._v(
                          " " + _vm._s(_vm.activeData.__config__.标题) + " "
                        )
                      ])
                    : _vm._e(),
                  _vm.activeData.__config__.label !== undefined &&
                  _vm.activeData.__config__.type !== "litigant"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "标题",
                            prop: "__config__.label",
                            rules: [
                              {
                                required: true,
                                message: "请输入标题",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入标题" },
                            on: { input: _vm.changeRenderKey },
                            model: {
                              value: _vm.activeData.__config__.label,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "label",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.label"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.changeContacts
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "指定关联审批",
                            prop: "__config__.approval",
                            rules: [
                              {
                                required: true,
                                message: "请选择指定关联审批",
                                trigger: "change"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-cascader", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              options: _vm.activeData.__config__.options,
                              "show-all-levels": false,
                              props: {
                                label: "name",
                                value: "id",
                                multiple: true,
                                emitPath: false
                              },
                              "collapse-tags":
                                _vm.activeData.__config__.approval.length > 6
                                  ? true
                                  : false
                            },
                            model: {
                              value: _vm.activeData.__config__.approval,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "approval",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.approval"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.placeholder !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "占位提示",
                            prop: "placeholder",
                            rules: [
                              {
                                required: true,
                                message: "请输入占位提示",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入占位提示" },
                            on: { input: _vm.changeRenderKey },
                            model: {
                              value: _vm.activeData.placeholder,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "placeholder", $$v)
                              },
                              expression: "activeData.placeholder"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.span !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "表单栅格" } },
                        [
                          _c("el-slider", {
                            staticStyle: { width: "90%" },
                            attrs: { max: 24, min: 1, marks: { 12: "" } },
                            on: { change: _vm.spanChange },
                            model: {
                              value: _vm.activeData.__config__.span,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData.__config__, "span", $$v)
                              },
                              expression: "activeData.__config__.span"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.layout === "rowFormItem" &&
                  _vm.activeData.gutter !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "栅格间隔" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "栅格间隔" },
                            model: {
                              value: _vm.activeData.gutter,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "gutter", $$v)
                              },
                              expression: "activeData.gutter"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.layout === "rowFormItem" &&
                  _vm.activeData.type !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "布局模式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "type", $$v)
                                },
                                expression: "activeData.type"
                              }
                            },
                            [
                              _c("el-radio-button", {
                                attrs: { label: "default" }
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "flex" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.justify !== undefined &&
                  _vm.activeData.type === "flex"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "水平排列" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择水平排列" },
                              model: {
                                value: _vm.activeData.justify,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "justify", $$v)
                                },
                                expression: "activeData.justify"
                              }
                            },
                            _vm._l(_vm.justifyOptions, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.align !== undefined &&
                  _vm.activeData.type === "flex"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "垂直排列" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.align,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "align", $$v)
                                },
                                expression: "activeData.align"
                              }
                            },
                            [
                              _c("el-radio-button", {
                                attrs: { label: "top" }
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "middle" }
                              }),
                              _c("el-radio-button", {
                                attrs: { label: "bottom" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.labelWidth !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "标签宽度" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "number",
                              placeholder: "请输入标签宽度"
                            },
                            model: {
                              value: _vm.activeData.__config__.labelWidth,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "labelWidth",
                                  _vm._n($$v)
                                )
                              },
                              expression: "activeData.__config__.labelWidth"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.style &&
                  _vm.activeData.style.width !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件宽度" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入组件宽度",
                              clearable: ""
                            },
                            model: {
                              value: _vm.activeData.style.width,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData.style, "width", $$v)
                              },
                              expression: "activeData.style.width"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__vModel__ !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "默认值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData.__config__.defaultValue
                              ),
                              placeholder: "请输入默认值"
                            },
                            on: { input: _vm.onDefaultValueInput }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__slot__ &&
                  _vm.activeData.__slot__.prepend !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "前缀" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入前缀" },
                            model: {
                              value: _vm.activeData.__slot__.prepend,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__slot__,
                                  "prepend",
                                  $$v
                                )
                              },
                              expression: "activeData.__slot__.prepend"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__slot__ &&
                  _vm.activeData.__slot__.append !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "后缀" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入后缀" },
                            model: {
                              value: _vm.activeData.__slot__.append,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData.__slot__, "append", $$v)
                              },
                              expression: "activeData.__slot__.append"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["prefix-icon"] !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: { label: "前图标" }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入前图标名称" },
                              model: {
                                value: _vm.activeData["prefix-icon"],
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "prefix-icon", $$v)
                                },
                                expression: "activeData['prefix-icon']"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-thumb"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openIconsDialog("prefix-icon")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v(" 选择 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["suffix-icon"] !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: false,
                              expression: "false"
                            }
                          ],
                          attrs: { label: "后图标" }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入后图标名称" },
                              model: {
                                value: _vm.activeData["suffix-icon"],
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "suffix-icon", $$v)
                                },
                                expression: "activeData['suffix-icon']"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-thumb"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openIconsDialog("suffix-icon")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v(" 选择 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["icon"] !== undefined &&
                  _vm.activeData.__config__.tag === "el-button"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮图标" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入按钮图标名称" },
                              model: {
                                value: _vm.activeData["icon"],
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "icon", $$v)
                                },
                                expression: "activeData['icon']"
                              }
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-thumb"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.openIconsDialog("icon")
                                    }
                                  },
                                  slot: "append"
                                },
                                [_vm._v(" 选择 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.autosize !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最小行数" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, placeholder: "最小行数" },
                            model: {
                              value: _vm.activeData.autosize.minRows,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.autosize,
                                  "minRows",
                                  $$v
                                )
                              },
                              expression: "activeData.autosize.minRows"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.autosize !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最大行数" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 1, placeholder: "最大行数" },
                            model: {
                              value: _vm.activeData.autosize.maxRows,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.autosize,
                                  "maxRows",
                                  $$v
                                )
                              },
                              expression: "activeData.autosize.maxRows"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowMin
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最小值" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "最小值" },
                            model: {
                              value: _vm.activeData.min,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "min", $$v)
                              },
                              expression: "activeData.min"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowMax
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "最大值",
                            prop: "max",
                            rules: [
                              {
                                required: true,
                                message: "请输入最大值",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "最大值" },
                            model: {
                              value: _vm.activeData.max,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "max", $$v)
                              },
                              expression: "activeData.max"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.height !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件高度" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "高度" },
                            on: { input: _vm.changeRenderKey },
                            model: {
                              value: _vm.activeData.height,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "height", $$v)
                              },
                              expression: "activeData.height"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isShowStep
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "步长" } },
                        [
                          _c("el-input-number", {
                            attrs: { placeholder: "步数" },
                            model: {
                              value: _vm.activeData.step,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "step", $$v)
                              },
                              expression: "activeData.step"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "精度" } },
                        [
                          _c("el-input-number", {
                            attrs: { min: 0, placeholder: "精度" },
                            model: {
                              value: _vm.activeData.precision,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "precision", $$v)
                              },
                              expression: "activeData.precision"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "按钮位置",
                            prop: "__config__.tag",
                            rules: [
                              {
                                required: true,
                                message: "请输入标题",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData["controls-position"],
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.activeData,
                                    "controls-position",
                                    $$v
                                  )
                                },
                                expression: "activeData['controls-position']"
                              }
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "" } }, [
                                _vm._v(" 默认 ")
                              ]),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "right" } },
                                [_vm._v(" 右侧 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.maxlength !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "最多输入" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入字符长度" },
                              model: {
                                value: _vm.activeData.maxlength,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "maxlength", $$v)
                                },
                                expression: "activeData.maxlength"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [
                                _vm._v(" 个字符 ")
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["active-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启提示", prop: "active-text" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入开启提示" },
                            model: {
                              value: _vm.activeData["active-text"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "active-text", $$v)
                              },
                              expression: "activeData['active-text']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭提示", prop: "inactive-text" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入关闭提示" },
                            model: {
                              value: _vm.activeData["inactive-text"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "inactive-text", $$v)
                              },
                              expression: "activeData['inactive-text']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["active-value"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData["active-value"]
                              ),
                              placeholder: "请输入开启值"
                            },
                            on: {
                              input: function($event) {
                                return _vm.onSwitchValueInput(
                                  $event,
                                  "active-value"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-value"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭值" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.setDefaultValue(
                                _vm.activeData["inactive-value"]
                              ),
                              placeholder: "请输入关闭值"
                            },
                            on: {
                              input: function($event) {
                                return _vm.onSwitchValueInput(
                                  $event,
                                  "inactive-value"
                                )
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.type !== undefined &&
                  "el-date-picker" === _vm.activeData.__config__.tag
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间类型", required: "" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择时间类型" },
                              on: { change: _vm.dateTypeChange },
                              model: {
                                value: _vm.activeData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "type", $$v)
                                },
                                expression: "activeData.type"
                              }
                            },
                            _vm._l(_vm.dateOptions, function(item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.name !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "文件字段名" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入上传文件字段名" },
                            model: {
                              value: _vm.activeData.name,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "name", $$v)
                              },
                              expression: "activeData.name"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.accept !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文件类型",
                            prop: "accept",
                            rules: [
                              {
                                required: true,
                                message: "请选择文件类型",
                                trigger: "change"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: { placeholder: "请选择文件类型" },
                              model: {
                                value: _vm.activeData.accept,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "accept", $$v)
                                },
                                expression: "activeData.accept"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: {
                                  label: "图片,mp4,excel,pdf,word",
                                  value:
                                    ".pdf,.xls,.xlsx,.doc,.docx,.mp4,image/*"
                                }
                              }),
                              _c("el-option", {
                                attrs: {
                                  label: "DWG,pdf,word,excel",
                                  value: ".pdf,.xls,.xlsx,.doc,.docx,.dwg"
                                }
                              }),
                              _c("el-option", {
                                attrs: { label: "图片", value: "image/*" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.fileSize !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "文件大小",
                            prop: "__config__.fileSize",
                            rules: [
                              {
                                required: true,
                                validator: _vm.validateFileSize,
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: { placeholder: "请输入文件大小" },
                              model: {
                                value: _vm.activeData.__config__.fileSize,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.activeData.__config__,
                                    "fileSize",
                                    $$v
                                  )
                                },
                                expression: "activeData.__config__.fileSize"
                              }
                            },
                            [
                              _c("template", { slot: "append" }, [_vm._v("MB")])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.action !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "上传地址" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入上传地址",
                              clearable: ""
                            },
                            model: {
                              value: _vm.activeData.action,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "action", $$v)
                              },
                              expression: "activeData.action"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["list-type"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "列表类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { size: "small" },
                              model: {
                                value: _vm.activeData["list-type"],
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "list-type", $$v)
                                },
                                expression: "activeData['list-type']"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "text" } },
                                [_vm._v(" text ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "picture" } },
                                [_vm._v(" picture ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "picture-card" } },
                                [_vm._v(" picture-card ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.type !== undefined &&
                  _vm.activeData.__config__.tag === "el-button"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              model: {
                                value: _vm.activeData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "type", $$v)
                                },
                                expression: "activeData.type"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "primary", value: "primary" }
                              }),
                              _c("el-option", {
                                attrs: { label: "success", value: "success" }
                              }),
                              _c("el-option", {
                                attrs: { label: "warning", value: "warning" }
                              }),
                              _c("el-option", {
                                attrs: { label: "danger", value: "danger" }
                              }),
                              _c("el-option", {
                                attrs: { label: "info", value: "info" }
                              }),
                              _c("el-option", {
                                attrs: { label: "text", value: "text" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.buttonText !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value:
                                "picture-card" !== _vm.activeData["list-type"],
                              expression:
                                "'picture-card' !== activeData['list-type']"
                            }
                          ],
                          attrs: {
                            label: "按钮文字",
                            prop: "__config__.buttonText",
                            rules: [
                              {
                                required: true,
                                message: "请输入占位提示",
                                trigger: "blur"
                              }
                            ]
                          }
                        },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入按钮文字" },
                            model: {
                              value: _vm.activeData.__config__.buttonText,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "buttonText",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.buttonText"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-button"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "按钮文字" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入按钮文字" },
                            model: {
                              value: _vm.activeData.__slot__.default,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__slot__,
                                  "default",
                                  $$v
                                )
                              },
                              expression: "activeData.__slot__.default"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["range-separator"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分隔符" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入分隔符" },
                            model: {
                              value: _vm.activeData["range-separator"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "range-separator", $$v)
                              },
                              expression: "activeData['range-separator']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["picker-options"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间段", required: "" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入时间段" },
                            model: {
                              value:
                                _vm.activeData["picker-options"]
                                  .selectableRange,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData["picker-options"],
                                  "selectableRange",
                                  $$v
                                )
                              },
                              expression:
                                "activeData['picker-options'].selectableRange"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.format !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "时间格式" } },
                        [
                          _c("el-input", {
                            attrs: {
                              value: _vm.activeData.format,
                              placeholder: "请输入时间格式",
                              disabled: ""
                            },
                            on: {
                              input: function($event) {
                                return _vm.setTimeValue($event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  ([
                    "el-select",
                    "el-select",
                    "el-radio-group",
                    "el-checkbox-group"
                  ].indexOf(_vm.activeData.__config__.tag) > -1 &&
                    !_vm.activeData.__config__.type) ||
                  _vm.activeData.__config__.type == "selectOptions"
                    ? [
                        _c("el-divider", [_vm._v("自定义选项")]),
                        _vm.activeData.__config__.dataType
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "数据类型",
                                  prop: "__config__.dataType",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择数据类型",
                                      trigger: "blur"
                                    }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.activeData.__config__.dataType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "dataType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.dataType"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "dynamic" } },
                                      [_vm._v(" 动态数据 ")]
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "static" } },
                                      [_vm._v(" 静态数据 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeData.__config__.dataType === "dynamic"
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "接口地址",
                                    prop: "__config__.url",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入接口地址",
                                        trigger: "blur"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        title: _vm.activeData.__config__.url,
                                        placeholder: "请输入接口地址",
                                        clearable: ""
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.$emit(
                                            "fetch-data",
                                            _vm.activeData
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.activeData.__config__.url,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.activeData.__config__,
                                            "url",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.__config__.url"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          style: { width: "85px" },
                                          attrs: { slot: "prepend" },
                                          on: {
                                            change: function($event) {
                                              return _vm.$emit(
                                                "fetch-data",
                                                _vm.activeData
                                              )
                                            }
                                          },
                                          slot: "prepend",
                                          model: {
                                            value:
                                              _vm.activeData.__config__.method,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.__config__,
                                                "method",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.__config__.method"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "get",
                                              value: "get"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "post",
                                              value: "post"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "put",
                                              value: "put"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "delete",
                                              value: "delete"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据位置" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入数据位置" },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$emit(
                                          "fetch-data",
                                          _vm.activeData
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.activeData.__config__.dataPath,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "dataPath",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.dataPath"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.activeData.props && _vm.activeData.props.props
                                ? [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "标签键名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入标签键名"
                                          },
                                          model: {
                                            value:
                                              _vm.activeData.props.props.label,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.props.props,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.props.props.label"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "值键名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入值键名"
                                          },
                                          model: {
                                            value:
                                              _vm.activeData.props.props.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.props.props,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.props.props.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.dataType === "static"
                          ? [
                              _vm.activeData.__slot__.options.length
                                ? _c(
                                    "draggable",
                                    {
                                      attrs: {
                                        list: _vm.activeData.__slot__.options,
                                        animation: 340,
                                        group: "selectItem",
                                        handle: ".option-drag"
                                      }
                                    },
                                    _vm._l(
                                      _vm.activeData.__slot__.options,
                                      function(item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "select-item"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "select-line-icon option-drag"
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-s-operation"
                                                })
                                              ]
                                            ),
                                            _c("el-input", {
                                              attrs: {
                                                placeholder: "选项值",
                                                size: "small",
                                                value: item.label
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.setOptionValue(
                                                    item,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.label,
                                                callback: function($$v) {
                                                  _vm.$set(item, "label", $$v)
                                                },
                                                expression: "item.label"
                                              }
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "close-btn select-line-icon",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.activeData.__slot__.options.splice(
                                                      index,
                                                      1
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline"
                                                })
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    0
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "text-align": "center",
                                        "line-height": "60px",
                                        color: "#909399"
                                      }
                                    },
                                    [_vm._v(" 暂无数据 ")]
                                  ),
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "padding-bottom": "0" },
                                      attrs: {
                                        icon: "el-icon-circle-plus-outline",
                                        type: "text"
                                      },
                                      on: { click: _vm.addSelectItem }
                                    },
                                    [_vm._v(" 添加选项 ")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { "padding-bottom": "0" },
                                      attrs: {
                                        icon: "el-icon-circle-plus-outline",
                                        type: "text"
                                      },
                                      on: { click: _vm.batchEdit }
                                    },
                                    [_vm._v(" 批量增加 ")]
                                  ),
                                  _vm.activeData.isAssociation
                                    ? _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "padding-bottom": "0"
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.batchAssociation(
                                                _vm.activeData
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f("ifIsAssociation")(
                                                  _vm.activeData.__slot__
                                                    .options
                                                )
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.checkList.includes(_vm.activeData.__vModel__)
                                ? _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px"
                                      },
                                      attrs: {
                                        size: "mini",
                                        loading: _vm.togetherLoading,
                                        type: "primary",
                                        icon: "el-icon-refresh-right"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitTogether(
                                            _vm.activeData
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("同步")]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _c("el-divider")
                      ]
                    : _vm._e(),
                  ["el-cascader", "el-table"].includes(
                    _vm.activeData.__config__.tag
                  ) && !_vm.activeData.__config__.type
                    ? [
                        _c("el-divider", [_vm._v("自定义选项")]),
                        _vm.activeData.__config__.dataType
                          ? _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "数据类型",
                                  prop: "__config__.dataType",
                                  rules: [
                                    {
                                      required: true,
                                      message: "请选择数据类型",
                                      trigger: "blur"
                                    }
                                  ]
                                }
                              },
                              [
                                _c(
                                  "el-radio-group",
                                  {
                                    attrs: { size: "small" },
                                    model: {
                                      value: _vm.activeData.__config__.dataType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "dataType",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.dataType"
                                    }
                                  },
                                  [
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "dynamic" } },
                                      [_vm._v(" 动态数据 ")]
                                    ),
                                    _c(
                                      "el-radio-button",
                                      { attrs: { label: "static" } },
                                      [_vm._v(" 静态数据 ")]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.activeData.__config__.dataType === "dynamic"
                          ? [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "接口地址",
                                    prop: "__config__.url",
                                    rules: [
                                      {
                                        required: true,
                                        message: "请输入接口地址",
                                        trigger: "blur"
                                      }
                                    ]
                                  }
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        title: _vm.activeData.__config__.url,
                                        placeholder: "请输入接口地址",
                                        clearable: ""
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.$emit(
                                            "fetch-data",
                                            _vm.activeData
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.activeData.__config__.url,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.activeData.__config__,
                                            "url",
                                            $$v
                                          )
                                        },
                                        expression: "activeData.__config__.url"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          style: { width: "85px" },
                                          attrs: { slot: "prepend" },
                                          on: {
                                            change: function($event) {
                                              return _vm.$emit(
                                                "fetch-data",
                                                _vm.activeData
                                              )
                                            }
                                          },
                                          slot: "prepend",
                                          model: {
                                            value:
                                              _vm.activeData.__config__.method,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.__config__,
                                                "method",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.__config__.method"
                                          }
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "get",
                                              value: "get"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "post",
                                              value: "post"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "put",
                                              value: "put"
                                            }
                                          }),
                                          _c("el-option", {
                                            attrs: {
                                              label: "delete",
                                              value: "delete"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "数据位置" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入数据位置" },
                                    on: {
                                      blur: function($event) {
                                        return _vm.$emit(
                                          "fetch-data",
                                          _vm.activeData
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.activeData.__config__.dataPath,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.activeData.__config__,
                                          "dataPath",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "activeData.__config__.dataPath"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm.activeData.props && _vm.activeData.props.props
                                ? [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "标签键名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入标签键名"
                                          },
                                          model: {
                                            value:
                                              _vm.activeData.props.props.label,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.props.props,
                                                "label",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.props.props.label"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "值键名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入值键名"
                                          },
                                          model: {
                                            value:
                                              _vm.activeData.props.props.value,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.props.props,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.props.props.value"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "子级键名" } },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            placeholder: "请输入子级键名"
                                          },
                                          model: {
                                            value:
                                              _vm.activeData.props.props
                                                .children,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.activeData.props.props,
                                                "children",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "activeData.props.props.children"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ]
                          : _vm._e(),
                        _vm.activeData.__config__.dataType === "static"
                          ? _c("el-tree", {
                              attrs: {
                                draggable: "",
                                data: _vm.activeData.options,
                                "node-key": "id",
                                "expand-on-click-node": false,
                                "render-content": _vm.renderContent
                              }
                            })
                          : _vm._e(),
                        _vm.activeData.__config__.dataType === "static"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "20px" } },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "padding-bottom": "0" },
                                    attrs: {
                                      icon: "el-icon-circle-plus-outline",
                                      type: "text"
                                    },
                                    on: { click: _vm.addTreeItem }
                                  },
                                  [_vm._v(" 添加父级 ")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("el-divider")
                      ]
                    : _vm._e(),
                  _vm.activeData["active-color"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "开启颜色" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.activeData["active-color"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "active-color", $$v)
                              },
                              expression: "activeData['active-color']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["inactive-color"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "关闭颜色" } },
                        [
                          _c("el-color-picker", {
                            model: {
                              value: _vm.activeData["inactive-color"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "inactive-color", $$v)
                              },
                              expression: "activeData['inactive-color']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.showLabel !== undefined &&
                  _vm.activeData.__config__.labelWidth !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示标签" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.__config__.showLabel,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "showLabel",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.showLabel"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.branding !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "品牌烙印" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { input: _vm.changeRenderKey },
                            model: {
                              value: _vm.activeData.branding,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "branding", $$v)
                              },
                              expression: "activeData.branding"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["allow-half"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "允许半选" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData["allow-half"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "allow-half", $$v)
                              },
                              expression: "activeData['allow-half']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-text"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "辅助文字" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { change: _vm.rateTextChange },
                            model: {
                              value: _vm.activeData["show-text"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "show-text", $$v)
                              },
                              expression: "activeData['show-text']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-score"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示分数" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { change: _vm.rateScoreChange },
                            model: {
                              value: _vm.activeData["show-score"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "show-score", $$v)
                              },
                              expression: "activeData['show-score']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-stops"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示间断点" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData["show-stops"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "show-stops", $$v)
                              },
                              expression: "activeData['show-stops']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.range !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "范围选择" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { change: _vm.rangeChange },
                            model: {
                              value: _vm.activeData.range,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "range", $$v)
                              },
                              expression: "activeData.range"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.border !== undefined &&
                  _vm.activeData.__config__.optionType === "default"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否带边框" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.__config__.border,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "border",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.border"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-color-picker"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "颜色格式" } },
                        [
                          _c(
                            "el-select",
                            {
                              style: { width: "100%" },
                              attrs: {
                                placeholder: "请选择颜色格式",
                                clearable: ""
                              },
                              on: { change: _vm.colorFormatChange },
                              model: {
                                value: _vm.activeData["color-format"],
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "color-format", $$v)
                                },
                                expression: "activeData['color-format']"
                              }
                            },
                            _vm._l(_vm.colorFormatOptions, function(
                              item,
                              index
                            ) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.size !== undefined &&
                  (_vm.activeData.__config__.optionType === "button" ||
                    _vm.activeData.__config__.border ||
                    _vm.activeData.__config__.tag === "el-color-picker" ||
                    _vm.activeData.__config__.tag === "el-button")
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "组件尺寸" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.activeData.size,
                                callback: function($$v) {
                                  _vm.$set(_vm.activeData, "size", $$v)
                                },
                                expression: "activeData.size"
                              }
                            },
                            [
                              _c(
                                "el-radio-button",
                                { attrs: { label: "medium" } },
                                [_vm._v(" 中等 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "small" } },
                                [_vm._v(" 较小 ")]
                              ),
                              _c(
                                "el-radio-button",
                                { attrs: { label: "mini" } },
                                [_vm._v(" 迷你 ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["show-word-limit"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "输入统计" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData["show-word-limit"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "show-word-limit", $$v)
                              },
                              expression: "activeData['show-word-limit']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-input-number"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "严格步数" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData["step-strictly"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "step-strictly", $$v)
                              },
                              expression: "activeData['step-strictly']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-cascader" &&
                  _vm.activeData.__config__.type !== "approval" &&
                  _vm.activeData.__config__.type !== "litigant"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "任选层级" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled:
                                _vm.activeData.__config__.type == "contacts" ||
                                _vm.activeData.__config__.type == "litigant",
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.props.props.checkStrictly,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.props.props,
                                  "checkStrictly",
                                  $$v
                                )
                              },
                              expression: "activeData.props.props.checkStrictly"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-cascader" &&
                  _vm.activeData.__config__.type !== "litigant"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否多选" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              disabled:
                                _vm.activeData.__config__.type == "litigant",
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.props.props.multiple,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.props.props,
                                  "multiple",
                                  $$v
                                )
                              },
                              expression: "activeData.props.props.multiple"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-cascader"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "可否筛选" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.filterable,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "filterable", $$v)
                              },
                              expression: "activeData.filterable"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.clearable !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "能否清空" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.clearable,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "clearable", $$v)
                              },
                              expression: "activeData.clearable"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.showTip !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "显示提示" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.__config__.showTip,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "showTip",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.showTip"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-upload"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "多选文件" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.multiple,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "multiple", $$v)
                              },
                              expression: "activeData.multiple"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData["auto-upload"] !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "自动上传" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData["auto-upload"],
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "auto-upload", $$v)
                              },
                              expression: "activeData['auto-upload']"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.readonly !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否只读" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.readonly,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "readonly", $$v)
                              },
                              expression: "activeData.readonly"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.disabled !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否禁用" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.disabled,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "disabled", $$v)
                              },
                              expression: "activeData.disabled"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-select"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "能否搜索" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.filterable,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "filterable", $$v)
                              },
                              expression: "activeData.filterable"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tag === "el-select"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否多选" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { change: _vm.multipleChange },
                            model: {
                              value: _vm.activeData.multiple,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "multiple", $$v)
                              },
                              expression: "activeData.multiple"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.required !== undefined
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否必填" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.__config__.required,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "required",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.required"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.selectType == "radio" ||
                  _vm.activeData.__config__.selectType == "checkbox"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "隐藏选项" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            on: { change: _vm.showRadioChange },
                            model: {
                              value: _vm.activeData.hideRadio,
                              callback: function($$v) {
                                _vm.$set(_vm.activeData, "hideRadio", $$v)
                              },
                              expression: "activeData.hideRadio"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.type === "amountMoney"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "是否大写" } },
                        [
                          _c("el-switch", {
                            model: {
                              value: _vm.activeData.__config__.ifBig,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "ifBig",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.ifBig"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.tagIcon === "date-range" ||
                  _vm.activeData.__config__.tagIcon === "time-range"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "计算时长" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-color": "#5669ec",
                              "inactive-color": "#5669ec1a"
                            },
                            model: {
                              value: _vm.activeData.__config__.ifRange,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activeData.__config__,
                                  "ifRange",
                                  $$v
                                )
                              },
                              expression: "activeData.__config__.ifRange"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.activeData.__config__.type === "amountMoney"
                    ? _c("el-form-item", { attrs: { label: "计算公式" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "rightBtn",
                            on: { click: _vm.editFormula }
                          },
                          [_vm._v("点击编辑")]
                        )
                      ])
                    : _vm._e(),
                  Array.isArray(_vm.activeData.__config__.regList)
                    ? [
                        _c("el-divider", [_vm._v("正则校验")]),
                        _vm._l(_vm.activeData.__config__.regList, function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            { key: index, staticClass: "reg-item" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "close-btn",
                                  on: {
                                    click: function($event) {
                                      return _vm.activeData.__config__.regList.splice(
                                        index,
                                        1
                                      )
                                    }
                                  }
                                },
                                [_c("i", { staticClass: "el-icon-close" })]
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "表达式" } },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入正则" },
                                    model: {
                                      value: item.pattern,
                                      callback: function($$v) {
                                        _vm.$set(item, "pattern", $$v)
                                      },
                                      expression: "item.pattern"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: { "margin-bottom": "0" },
                                  attrs: { label: "错误提示" }
                                },
                                [
                                  _c("el-input", {
                                    attrs: { placeholder: "请输入错误提示" },
                                    model: {
                                      value: item.message,
                                      callback: function($$v) {
                                        _vm.$set(item, "message", $$v)
                                      },
                                      expression: "item.message"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "20px" } },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  icon: "el-icon-circle-plus-outline",
                                  type: "text"
                                },
                                on: { click: _vm.addReg }
                              },
                              [_vm._v(" 添加规则 ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "el-form",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.currentTab === "form",
                      expression: "currentTab === 'form'"
                    }
                  ],
                  attrs: { size: "small", "label-position": "top" }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入表单名（ref）" },
                        model: {
                          value: _vm.formConf.formRef,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "formRef", $$v)
                          },
                          expression: "formConf.formRef"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单模型" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入数据模型" },
                        model: {
                          value: _vm.formConf.formModel,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "formModel", $$v)
                          },
                          expression: "formConf.formModel"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "校验模型" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入校验模型" },
                        model: {
                          value: _vm.formConf.formRules,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "formRules", $$v)
                          },
                          expression: "formConf.formRules"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { label: "表单尺寸" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.size,
                            callback: function($$v) {
                              _vm.$set(_vm.formConf, "size", $$v)
                            },
                            expression: "formConf.size"
                          }
                        },
                        [
                          _c(
                            "el-radio-button",
                            { attrs: { label: "medium" } },
                            [_vm._v(" 中等 ")]
                          ),
                          _c("el-radio-button", { attrs: { label: "small" } }, [
                            _vm._v(" 较小 ")
                          ]),
                          _c("el-radio-button", { attrs: { label: "mini" } }, [
                            _vm._v(" 迷你 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { label: "标签对齐" }
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.formConf.labelPosition,
                            callback: function($$v) {
                              _vm.$set(_vm.formConf, "labelPosition", $$v)
                            },
                            expression: "formConf.labelPosition"
                          }
                        },
                        [
                          _c("el-radio-button", { attrs: { label: "left" } }, [
                            _vm._v(" 左对齐 ")
                          ]),
                          _c("el-radio-button", { attrs: { label: "right" } }, [
                            _vm._v(" 右对齐 ")
                          ]),
                          _c("el-radio-button", { attrs: { label: "top" } }, [
                            _vm._v(" 顶部对齐 ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { label: "标签宽度" }
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "number",
                          placeholder: "请输入标签宽度"
                        },
                        model: {
                          value: _vm.formConf.labelWidth,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "labelWidth", _vm._n($$v))
                          },
                          expression: "formConf.labelWidth"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { label: "栅格间隔" }
                    },
                    [
                      _c("el-input-number", {
                        attrs: { min: 0, placeholder: "栅格间隔" },
                        model: {
                          value: _vm.formConf.gutter,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "gutter", $$v)
                          },
                          expression: "formConf.gutter"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "禁用表单" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#5669ec",
                          "inactive-color": "#5669ec1a"
                        },
                        model: {
                          value: _vm.formConf.disabled,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "disabled", $$v)
                          },
                          expression: "formConf.disabled"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "表单按钮" } },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#5669ec",
                          "inactive-color": "#5669ec1a"
                        },
                        model: {
                          value: _vm.formConf.formBtns,
                          callback: function($$v) {
                            _vm.$set(_vm.formConf, "formBtns", $$v)
                          },
                          expression: "formConf.formBtns"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: false,
                          expression: "false"
                        }
                      ],
                      attrs: { label: "显示未选中组件边框" }
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#5669ec",
                          "inactive-color": "#5669ec1a"
                        },
                        model: {
                          value: _vm.formConf.unFocusedComponentBorder,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.formConf,
                              "unFocusedComponentBorder",
                              $$v
                            )
                          },
                          expression: "formConf.unFocusedComponentBorder"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("treeNode-dialog", {
        attrs: { visible: _vm.dialogVisible, title: "添加选项" },
        on: {
          "update:visible": function($event) {
            _vm.dialogVisible = $event
          },
          commit: _vm.addNode
        }
      }),
      _c("icons-dialog", {
        attrs: {
          visible: _vm.iconsVisible,
          current: _vm.activeData[_vm.currentIconModel]
        },
        on: {
          "update:visible": function($event) {
            _vm.iconsVisible = $event
          },
          select: _vm.setIcon
        }
      }),
      _vm.batchIncreaseVisible
        ? _c(
            "Customdialog",
            {
              attrs: {
                dialogWidth: "30%",
                title: "批量编辑",
                dialogHeight: "340px",
                isFooter: true
              },
              on: { closeDialog: _vm.batchEdit, submitDialog: _vm.batchSubmit }
            },
            [
              _c("template", { slot: "dialogMain" }, [
                _c(
                  "div",
                  { staticStyle: { padding: "16px" } },
                  [
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("每行对应一个选项")
                    ]),
                    _c("el-input", {
                      attrs: {
                        type: "textarea",
                        rows: 8,
                        placeholder: "请输入内容"
                      },
                      model: {
                        value: _vm.textarea,
                        callback: function($$v) {
                          _vm.textarea = $$v
                        },
                        expression: "textarea"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.associationVisible
        ? _c(
            "Customdialog",
            {
              attrs: {
                dialogWidth: "60%",
                title: "选项关联",
                isFooter: true,
                dialogHeight: "440px"
              },
              on: {
                closeDialog: _vm.associationEdit,
                submitDialog: _vm.associationSubmit
              }
            },
            [
              _c("template", { slot: "dialogMain" }, [
                _c("div", { staticStyle: { padding: "16px" } }, [
                  _c("span", [_vm._v("根据选择的选项，显示其他控件")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-table",
                        { attrs: { data: _vm.activeData.__slot__.options } },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "label",
                              label: "当前选项",
                              width: "300"
                            }
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "lable", label: "关联选项" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(scope) {
                                    return [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            multiple: "",
                                            placeholder: "请选择"
                                          },
                                          model: {
                                            value: scope.row.defaultChecked,
                                            callback: function($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "defaultChecked",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "scope.row.defaultChecked"
                                          }
                                        },
                                        _vm._l(_vm.list, function(item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                              disabled: item.disabled
                                            }
                                          })
                                        }),
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2798441086
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            2
          )
        : _vm._e(),
      _vm.calculationVisible
        ? _c(
            "Customdialog",
            {
              attrs: {
                dialogWidth: "600px",
                title: "计算公式",
                isFooter: true,
                dialogHeight: "420px"
              },
              on: {
                closeDialog: _vm.calculationCancel,
                submitDialog: _vm.calculationSubmit
              }
            },
            [
              _c("template", { slot: "dialogMain" }, [
                _c("div", { staticStyle: { padding: "16px" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        border: "1px solid #8f8c8c",
                        width: "100%",
                        height: "160px",
                        "margin-bottom": "20px",
                        position: "relative",
                        padding: "10px",
                        "padding-left": "10px"
                      }
                    },
                    [
                      _vm._l(_vm.FormulaList, function(item, index) {
                        return _c(
                          "el-tag",
                          {
                            key: index,
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px"
                            },
                            attrs: {
                              size: "small",
                              type: item.type == 2 ? "danger" : ""
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                item.type == 1
                                  ? item.val.__config__.label
                                  : item.val
                              )
                            )
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            right: "0",
                            bottom: "0",
                            "padding-right": "20px"
                          }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                icon: "el-icon-refresh-right"
                              },
                              on: { click: _vm.clearFormula }
                            },
                            [_vm._v("清空")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { color: "tomato" },
                              attrs: { type: "text", icon: "el-icon-back" },
                              on: { click: _vm.deleteFormula }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "90px" } }, [
                      _vm._v("计算对象 ：")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { width: "460px" } },
                      _vm._l(_vm.filteredArray, function(item) {
                        return _c(
                          "el-tag",
                          {
                            key: item.__config__.formId,
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.calculation(item, 1)
                              }
                            }
                          },
                          [_vm._v(" " + _vm._s(item.__config__.label))]
                        )
                      }),
                      1
                    )
                  ]),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "90px" } }, [
                      _vm._v("计算符号 ：")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { width: "460px" } },
                      [
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.calculation("+", 2)
                              }
                            }
                          },
                          [_vm._v("+")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.calculation("-", 2)
                              }
                            }
                          },
                          [_vm._v("-")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.calculation("*", 2)
                              }
                            }
                          },
                          [_vm._v("×")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.calculation("/", 2)
                              }
                            }
                          },
                          [_vm._v("÷")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.calculation("(", 2)
                              }
                            }
                          },
                          [_vm._v("(")]
                        ),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small", type: "danger" },
                            on: {
                              click: function($event) {
                                _vm.calculation(")", 2)
                              }
                            }
                          },
                          [_vm._v(")")]
                        )
                      ],
                      1
                    )
                  ]),
                  _c("div", { staticStyle: { display: "flex" } }, [
                    _c("div", { staticStyle: { width: "90px" } }, [
                      _vm._v("数字键盘 ：")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { width: "460px" } },
                      [
                        _vm._l(10, function(item, index) {
                          return _c(
                            "el-tag",
                            {
                              key: index,
                              staticStyle: {
                                "margin-right": "16px",
                                "margin-bottom": "10px",
                                cursor: "pointer"
                              },
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.calculation(index, 3)
                                }
                              }
                            },
                            [_vm._v(_vm._s(index))]
                          )
                        }),
                        _c(
                          "el-tag",
                          {
                            staticStyle: {
                              "margin-right": "16px",
                              "margin-bottom": "10px",
                              cursor: "pointer"
                            },
                            attrs: { size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.calculation(".", 3)
                              }
                            }
                          },
                          [_vm._v(".")]
                        )
                      ],
                      2
                    )
                  ])
                ])
              ])
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }