<template>
    <div class="advanced_setup">
        <div class="left">
            <div class="setup_list">
                <div class="setup_item" v-for="item in setupList" :class="setupCode == item.code ? 'setup_active' : ''"
                    :key="item.code" @click="chosseSetup(item.code)">
                    {{ item.name }}
                </div>
            </div>
        </div>
        <div class="right">
            <p>{{ setupList.find((e) => e.code == setupCode).name }}</p>
            <template v-if="setupCode == 1">
                <el-row>
                    <el-col :span="8" v-for="item in deployList" :key="item.name">
                        <div class="deploy_item">
                            <div class="top">
                                <div>
                                    <img :src="item.icon" alt="" />
                                    <span>{{ item.name }}</span>
                                </div>
                                <el-switch v-model="item.value" active-color="#5669ec" inactive-color="#5669ec1a"
                                    :active-value="1" :inactive-value="0">
                                </el-switch>
                            </div>
                            <div class="bottom">{{ item.text }}</div>
                        </div>
                    </el-col>
                </el-row>
            </template>
            <template v-if="setupCode == 2">
                <div class="control_limit">
                    <p>管理范围</p>
                    <div class="list mb20">
                        <!-- <div class="item" :class="authType == item.value ? 'limit_active' : ''"
                            v-for="item in controlLimit" :key="item.value" @click="chooseLimit(item.value)">
                            {{ item.name }}
                        </div> -->
                        <div class="item" v-for="item in controlLimit" :key="item.value" @click="chooseLimit(item.value)">
                                <img src="@/assets/check4.png" alt="" v-if="authType == item.value" />
                                <img src="@/assets/check3.png" alt="" v-else/>
                                <div>{{ item.name }}</div>
                        </div>
                    </div>

                    <template v-if="authType == 5">
                        <el-button type="text" @click="openRange"
                            v-if="customRange.uid.length || customRange.dept.length">
                            <span v-if="customRange.dept.length">{{ customRange.dept.length }}个部门</span>
                            <span v-if="customRange.uid.length && customRange.dept.length">,</span>
                            <span v-if="customRange.uid.length">{{ customRange.uid.length }}个人员</span>
                        </el-button>
                        <el-button type="text" @click="openRange" v-else>请选择</el-button>
                    </template>
                </div>
            </template>
        </div>
        <initiatingRange ref="initiatingRange" @confirm="getChooseList" addType="0" :selectedList="chooseList" />
    </div>
</template>

<script>
import initiatingRange from "../../initiatingRange.vue";
export default {
    components: {
        initiatingRange,
    },
    data() {
        return {
            chooseList: [],
            // 高级设置
            setupList: [
                { name: "流程设置", code: 1 },
                { name: "权限管理", code: 2 },
            ],
            deployList: [
                {
                    icon: require("@/assets/Group1.png"),
                    name: "自动去重",
                    value: 0,
                    label: "isDuplicate",
                    text: "审批流程中审批人重复出现时，只需审批一次其余自动通过",
                },
                {
                    icon: require("@/assets/Group2.png"),
                    name: "自动审批",
                    value: 1,
                    label: "isSponsorApproval",
                    text: "发起人节点自动通过",
                },
                {
                    icon: require("@/assets/Group3.png"),
                    name: "允许加签",
                    value: 1,
                    label: "isSign",
                    text: "开启后审批单可以新增临时审批人",
                },
                {
                    icon: require("@/assets/Group4.png"),
                    name: "撤销",
                    value: 1,
                    label: "isCancel",
                    text: "开启后发起人可以撤销审批单",
                },
            ],
            controlLimit: [
                {
                    name: "全公司",
                    value: 1,
                },
                {
                    name: "自己负责的",
                    value: 0,
                },
                {
                    name: "同层级",
                    value: 2,
                },
                {
                    name: "本部门及部门以下",
                    value: 4,
                },
                {
                    name: "自定义",
                    value: 5,
                },
            ],
            setupCode: 1, //高级设置已选择
            authType: 1, //管理范围
            customRange: { dept: [], uid: [] },
        };
    },
    methods: {
        getChooseList(e) {
            this.customRange.dept = [];
            this.customRange.uid = [];
            e.map((item) => {
                this.customRange[item.type].push(item);
            });
        },
        openRange() {
            this.initscope();
            this.$refs.initiatingRange.dialogVisible = true;
        },
        // 处理发起范围
        initscope() {
            let arr = [];
            for (const key in this.customRange) {
                if (this.customRange[key].length) {
                    this.customRange[key].map((e) => {
                        e.type = key;
                    });
                    arr.push(...this.customRange[key]);
                }
            }
            setTimeout(() => {
                this.chooseList = arr;
            }, 100);
            // console.log()
            // console.log(this.chooseList, "chooseList");
            // this.$refs.initiatingRange.chooseList = JSON.parse(
            //   JSON.stringify(this.chooseList)
            // );
            // this.$refs.initiatingRange.applyTree();
        },
        chosseSetup(code) {
            this.setupCode = code;
        },
        chooseLimit(value) {
            this.authType = value;
        },
    },
};
</script>

<style lang="scss" scoped>
.advanced_setup {
    height: 100%;
    display: flex;

    .left {
        padding: 20px 0;
        flex: 1;
        border-right: 2px solid #f5f5f7;
        position: relative;

        .setup_list {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: calc(100% - 16px);

            .setup_item {
                border-radius: 4px;
                color: #848484;
                background: #f3f4f6;
                font-size: 13px;
                width: 100%;
                cursor: pointer;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .setup_active {
                background: #5669ec;
                color: #fff;

            }
        }
    }

    .right {
        flex: 4;
        padding: 16px 0 0 12px;

        >p {
            color: #1b1b1b;
            font-weight: 600;
            font-size: 14px;
            margin-bottom: 12px;
            margin-left: 4px;
        }

        .deploy_item {
            padding: 16px;
            border-radius: 12px;
            border: 1px solid #f5f5f7;
            height: 110px;
            background-color: #fff;
            margin: 0 4px 8px;

            .top {
                font-size: 13px;
                color: #1b1b1b;
                display: flex;
                justify-content: space-between;
                padding-bottom: 11px;
                align-items: center;
                height: 35px;
                border-bottom: 1px dashed #e4e5e7;
                margin-bottom: 11px;

                >div {
                    display: flex;
                    align-items: center;
                }

                img {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }
            }

            .bottom {
                color: #848484;
                font-size: 11px;
            }
        }
    }

    .control_limit {
        margin-left: 4px;

        p {
            font-size: 13px;
            color: #1b1b1b;
            margin-bottom: 16px;
            font-weight: 400;
        }

        .list {
            display: flex;
            // justify-content: space-around;
            gap: 16px;

            .item {
                // flex: 1;
                // text-align: center;
                // background: #f5f5f7;
                // border-radius: 8px;
                // padding: 10px 0;
                // border: 1px solid #f5f5f7;
                cursor: pointer;
                color: #848484;
                font-size: 14px;
                display: flex;
                align-items: center;
                margin-right: 16px;
                img{
                    width: 16px;
                    height: 16px;
                    margin-right: 4px;
                }
            }

            // .item:hover {
            //     border: 1px solid #060000;
            // }

            // .limit_active {
            //     border: 1px solid #060000;
            //     color: #060000;
            // }
        }
    }
}
</style>