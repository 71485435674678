<template>
  <div class="panel-tab__content">

    <el-form :model="flowConditionForm" label-width="90px" size="mini" @submit.native.prevent label-position="top">

      <el-form-item label="流转类型">

        <el-select v-model="flowConditionForm.type" @change="updateFlowType">

          <el-option label="普通流转路径" value="normal" />

          <el-option label="默认流转路径" value="default" />

          <el-option label="条件流转路径" value="condition" />

        </el-select>

      </el-form-item>

      <!-- 选择条件流转要显示条件内容 -->

      <el-form-item label="添加条件" v-if="flowConditionForm.type === 'condition'" key="condition"
        style="margin-bottom: 8px">

        <el-button round type="" @click="addConditionGroup">添加条件组</el-button>


      </el-form-item>

      <!-- <el-form-item label="条件格式" v-if="flowConditionForm.type === 'condition'" key="condition">

        <el-select v-model="flowConditionForm.conditionType">

          <el-option label="表达式" value="expression" />

          <el-option label="脚本" value="script" />

        </el-select>

      </el-form-item>

      <el-form-item label="表达式" v-if="flowConditionForm.conditionType && flowConditionForm.conditionType === 'expression'" key="express">

        <el-input v-model="flowConditionForm.body" style="width:192px;"  clearable @change="updateFlowCondition" />

      </el-form-item> -->

      <template v-if="flowConditionForm.conditionType &&

        flowConditionForm.conditionType === 'script'

      ">

        <el-form-item label="脚本语言" key="language">

          <el-input v-model="flowConditionForm.language" clearable @change="updateFlowCondition" />

        </el-form-item>

        <el-form-item label="脚本类型" key="scriptType">

          <el-select v-model="flowConditionForm.scriptType">

            <el-option label="内联脚本" value="inlineScript" />

            <el-option label="外部脚本" value="externalScript" />

          </el-select>

        </el-form-item>

        <el-form-item label="脚本" v-if="flowConditionForm.scriptType === 'inlineScript'" key="body">

          <el-input v-model="flowConditionForm.body" type="textarea" clearable @change="updateFlowCondition" />

        </el-form-item>

        <el-form-item label="资源地址" v-if="flowConditionForm.scriptType === 'externalScript'" key="resource">

          <el-input v-model="flowConditionForm.resource" clearable @change="updateFlowCondition" />

        </el-form-item>

      </template>

    </el-form>

    <!-- 条件组 -->

    <template>

      <el-form size="mini" :model="conditionForm" ref="validateForm">

        <div v-for="(item, index) in conditionForm.conditionList" :key="item.id" class="card">

          <el-card>

            <div slot="header" class="clearfix">

              <span>条件组</span>

              <el-button style="float: ri ght" icon="el-icon-delete" size="mini" circle
                @click="delCondition(index)"></el-button>

            </div>



            <main v-for="(sub, i) in item.checkConditionList" :key="i">

              <!-- 计步器 -->

              <template v-if="sub.formType == 'number' ||

                sub.formType == 'inumber' ||

                sub.formType == 'amountMoney' ||
                sub.formType == 'date-range'
              ">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="10">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.operator'

                      " :rules="rules">

                      <el-select v-model="sub.operator" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.operatorNumOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select> </el-form-item></el-col>

                  <el-col :span="10" v-if="sub.operator == '<' ||

                    sub.operator == '==' ||

                    sub.operator == '>' ||

                    sub.operator == '<=' ||

                    sub.operator == '>='

                  ">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>

                <!-- 介于 -->

                <el-row v-if="sub.operator == 'in'" :gutter="4">

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val1'

                      " :rules="rules">

                      <el-input-number size="mini" style="width: 100%" v-model="sub.val1"
                        controls-position="right"></el-input-number>

                    </el-form-item>

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra1'

                      " :rules="rules">

                      <el-select v-model="sub.extra1" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="4" style="text-align: center; font-size: 12px">

                    {{ sub.fieldName }}

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.extra2'

                      " :rules="rules">

                      <el-select v-model="sub.extra2" style="width: 100%" size="mini">

                        <el-option v-for="item in $store.state.bpm.extraOption" :key="item.id" :label="item.label"
                          :value="item.value" />

                      </el-select>

                    </el-form-item>

                  </el-col>

                  <el-col :span="5">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.val2'

                      " :rules="rules">

                      <el-input-number style="width: 100%" v-model="sub.val2" size="mini"
                        controls-position="right"></el-input-number>

                    </el-form-item>

                  </el-col>

                </el-row>

              </template>

              <!-- <template v-if="sub.formType == 'radio'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.valList'

                      " :rules="rules">

                      <el-radio-group v-model="sub.valList" style="width: 100%">

                        <el-radio :label="e.value" v-for="e in sub.optList" :key="e.label">{{ e.label }}</el-radio>

                      </el-radio-group>

                    </el-form-item>

                  </el-col>

                </el-row>

              </template> -->
              <template v-if="sub.formType == 'radio'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.valList'

                      " :rules="rules">
                      <el-checkbox-group v-model="sub.valList" style="width: 100%">

                        <el-checkbox :label="e.value" v-for="e in sub.optList" :key="e.label">{{ e.label
                          }}</el-checkbox>

                      </el-checkbox-group>

                    </el-form-item>

                  </el-col>

                </el-row>

              </template>

              <!-- 多选框 -->

              <template v-if="sub.formType == 'checkbox'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-row :gutter="8" style="margin-bottom: 0px">

                      <el-col :span="8">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.operator'

                          " :rules="rules">

                          <el-select v-model="sub.operator" style="width: 100%" size="mini">

                            <el-option v-for="item in $store.state.bpm.operatorOption" :key="item.id"
                              :label="item.label" :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                      <el-col :span="16">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.valList'

                          " :rules="rules">

                          <el-select v-model="sub.valList" style="width: 100%" size="mini" multiple>

                            <el-option v-for="item in sub.optList" :key="item.id" :label="item.label"
                              :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                    </el-row>

                  </el-col>

                </el-row>

              </template>
              <!-- 选项关联 -->
              <template v-if="sub.formType == 'selectOptions' || sub.formType == 'select'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-row :gutter="8" style="margin-bottom: 0px">

                      <el-col :span="8">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.operator'

                          " :rules="rules">

                          <el-select v-model="sub.operator" style="width: 100%" size="mini">

                            <el-option v-for="item in $store.state.bpm.operatorOption" :key="item.id"
                              :label="item.label" :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                      <el-col :span="16">

                        <el-form-item :prop="'conditionList.' +

                          index +

                          '.checkConditionList.' +

                          i +

                          '.valList'

                          " :rules="rules">

                          <el-select v-model="sub.valList" style="width: 100%" size="mini" multiple>

                            <el-option v-for="item in sub.optList" :key="item.id" :label="item.label"
                              :value="item.value" />

                          </el-select>

                        </el-form-item>

                      </el-col>

                    </el-row>

                  </el-col>

                </el-row>

              </template>

              <!-- 部门 -->

              <template v-if="sub.formType == 'department'">

                <el-row :gutter="8">

                  <el-col :span="4"> {{ sub.fieldName }}</el-col>

                  <el-col :span="20">

                    <el-form-item :prop="'conditionList.' +

                      index +

                      '.checkConditionList.' +

                      i +

                      '.valList'

                      " :rules="rules">

                      <el-cascader style="width: 100%" v-model="sub.valList" :options="deptTreeOptions[0].children"
                        :props="{

                          value: 'deptId',

                          label: 'deptName',

                          multiple: true,

                          checkStrictly: true

                        }"></el-cascader>

                    </el-form-item>

                  </el-col>

                </el-row>

              </template>


              <p v-if="i != item.checkConditionList.length - 1">且</p>

            </main>



            <el-button size="mini" icon="el-icon-plus" @click="selectionCriteria(index)">添加条件</el-button>

          </el-card>

          <div v-if="index != conditionForm.conditionList.length - 1">或</div>

        </div>

      </el-form>

    </template>
    <el-button type="primary" size="mini" :loading="loading" @click="saveExpression('validateForm')"
      v-if="conditionForm.conditionList.length && flowConditionForm.type === 'condition'"
      style="margin-top: 10px;">保存</el-button>

    <!-- <el-dialog title="条件" :visible.sync="dialogConditionVisible">

      <div v-for="(item, index) in conditionList" :key="item.id" class="card">

        <el-card>

          <el-form>

            <el-form-item> </el-form-item>

          </el-form>

          <el-button size="mini" icon="el-icon-plus" @click="selectionCriteria"

            >添加条件</el-button

          >

        </el-card>

        <div v-if="index != conditionList.length - 1">或</div>

      </div>



      <el-button

        type="primary"

        style="margin-top: 20px"

        @click="addConditionGroup"

        >添加条件组</el-button

      >

      <div slot="footer" class="dialog-footer">

        <el-button @click="updateFlowCondition">取 消</el-button>

        <el-button type="primary" @click="dialogConditionVisible = false"

          >确 定</el-button

        >

      </div>

    </el-dialog> -->
    <Customdialog v-if='selectionCriteriaVisible' :dialogWidth="'560px'" :title="'选择条件'" :dialogHeight="'200px'"
      :isFooter="true" @closeDialog="selectionCriteriaVisible = false" @submitDialog="affirmCheckList">
      <template slot="dialogMain">
        <div style="padding: 0 24px;">
          <div style="margin-bottom: 10px">

            请选择用来区分审批流程的条件字段 ，已选{{ checkList.length }}个

          </div>

          <el-checkbox-group v-model="checkList" @change="changeCheckList">


            <el-checkbox :label="item.fieldVal" v-for="(item, index) in formInfo" :key="index">{{ item.fieldName

              }}</el-checkbox>

          </el-checkbox-group>
        </div>
      </template>
    </Customdialog>
  </div>
</template>



<script>

import { createExpression } from "@/api/flow";
import Customdialog from '@/components/customdialog'

import { mapState } from "vuex";

export default {

  name: "FlowCondition",
  components: { Customdialog },

  props: {

    businessObject: Object,

    type: String,

    model: Object,

  },

  data() {

    return {

      loading: false,

      elementBaseInfo: {},

      conditionIndex: null, //当前条件组索引

      flowConditionForm: {},

      dialogConditionVisible: false,

      selectionCriteriaVisible: false,

      checkList: [],

      conditionForm: {

        conditionList: [],

      },

      formCheck: [],

      rules: {

        required: true,

        message: "不能为空",

        trigger: "blur",

      },

      // conditionList: [], //条件数据

    };

  },

  created() {

    // console.log(this.deptTreeOptions, "deptTreeOptions");

  },

  watch: {

    businessObject: {

      immediate: true,

      handler() {

        // console.log("businessObject变化", this.businessObject);

        this.$nextTick(() => this.resetFlowCondition());

      },

    },

    // conditionList: {

    //   immediate: false,

    //   deep: true,

    //   handler() {

    //     const attrObj = Object.create(null);

    //     attrObj["conditionList"] = this.conditionList;

    //     window.bpmnInstances.modeling.updateProperties(

    //       this.bpmnElement,

    //       attrObj

    //     );

    //   },

    // },

  },

  computed: {

    ...mapState({

      formInfo: ((state) => {
        var data = [];
        state.process.design.formInfo.forEach(e => {
          if ((e.formType == "number" || e.formType == "radio" || e.formType == "checkbox" || e.formType == "department" || e.formType == "select" || e.formType == "inumber" || e.formType == "date-range" || e.formType == "selectOptions" || e.formType == "amountMoney") && e.isRequired) {
            data.push(e);
          }
          if (e.formType == "rowFormItem") {
            e.children[0].forEach((err) => {
              if (err.__config__._type_ && err.__config__._type_ == "leave" && err.__vModel__ == 'field1724060921495') {
                data.push({
                  formType: err.__config__.type
                    ? err.__config__.type
                    : err.__config__.tagIcon,
                  fieldName: err.__config__.label,
                  fieldVal: err.__vModel__,
                  isRequired: err.__config__.required,
                  optList: e.options,
                });
              }
            })
          }
        });
        return data;

        // (state) => state.process.design.formInfo.filter((e) => (
        //   e.formType == "number" ||
        //   e.formType == "radio" ||
        //   e.formType == "checkbox" ||
        //   e.formType == "department" ||
        //   e.formType == "select" ||
        //   e.formType == "inumber" ||
        //   e.formType == "date-range" ||
        //   e.formType == "selectOptions" ||
        //   e.formType == "amountMoney") &&
        //   e.isRequired
        // )
      }),

      // formInfo: (state) => state.process.design.formInfo,

      deptTreeOptions: (state) => state.bpm.deptTreeOptions,

    }),

  },

  methods: {



    // 保存条件组

    saveExpression(formName) {

      // console.log(`output->`,this.conditionForm.conditionList)

      // return

      this.$refs[formName].validate((valid) => {

        if (valid) {

          let obj = {

            modelKey: this.model.key,

            edgeId: this.elementBaseInfo.id,

            expression: [],

          };

          obj.expression = this.conditionForm.conditionList.map((e) => {

            let operatorConditionList = [];

            let singleChoiceList = [];

            let multipleChoiceList = [];

            let deptChoiceList = [];

            if (e.checkConditionList && e.checkConditionList.length) {

              e.checkConditionList.map((i) => {

                if (i.formType == "number") {

                  operatorConditionList.push({

                    field: i.fieldVal,

                    operator: i.operator,

                    extra1: i.extra1 || "",

                    val1: i.val1,

                    extra2: i.extra2 || "",

                    val2: i.val2,

                  });

                }
                if (i.formType == "date-range") {
                  operatorConditionList.push({

                    field: i.fieldVal,

                    operator: i.operator,

                    extra1: i.extra1 || "",

                    val1: i.val1?.toFixed(1),

                    extra2: i.extra2 || "",

                    val2: i.val2?.toFixed(1),

                  });

                }

                if (i.formType == "amountMoney") {

                  operatorConditionList.push({

                    field: i.fieldVal,

                    operator: i.operator,

                    extra1: i.extra1 || "",

                    val1: i.val1?.toString(),

                    extra2: i.extra2 || "",

                    val2: i.val2?.toString(),

                  });

                }

                if (i.formType == "radio") {

                  singleChoiceList.push({

                    field: i.fieldVal,

                    valList: i.valList,

                  });

                }

                if (i.formType == "checkbox" || i.formType == "selectOptions" || i.formType == "select") {

                  multipleChoiceList.push({

                    field: i.fieldVal,

                    operator: i.operator,

                    valList: i.valList,

                  });

                }

                if (i.formType == "department") {

                  deptChoiceList.push({

                    field: i.fieldVal,

                    valList: i.valList.map((e) => {

                      return e[e.length - 1];

                    }),

                  });

                }

              });

            }



            return {

              operatorConditionList,

              singleChoiceList,

              multipleChoiceList,

              deptChoiceList,
            };

          });

          if (obj.expression) {

            this.createExpression(obj);

          }

          // console.log(obj, "obj");

        } else {

          return false;

        }

      });

    },

    async createExpression(obj) {

      this.loading = true;

      createExpression(obj)

        .then((res) => {
          let data = res.data;
          this.flowConditionForm.body = data;
          this.updateFlowCondition();
          const attrObj = Object.create(null);
          attrObj["conditionList"] = JSON.stringify(
            this.conditionForm.conditionList
          );
          window.bpmnInstances.modeling.updateProperties(
            this.bpmnElement,
            attrObj
          );
          this.loading = false;
          this.$message.success("保存成功");

        })

        .catch((res) => {

          this.loading = false;

        });



      // console.log(data);

    },

    // 生产条件表达式

    // async createExpression() {

    //   const { data, code } = await createExpression();

    // },

    affirmCheckList() {

      // console.log(this.checkList);

      let list = [];

      let arr = JSON.parse(JSON.stringify(this.formInfo));

      arr.filter((e) => {

        this.checkList.filter((i) => {

          if (e.fieldVal == i) {

            if (e.formType == "checkbox" || e.formType == "radio") {

              e.valList = [];

            }

            list.push(e);

          }

        });

      });

      this.$set(

        this.conditionForm.conditionList[this.conditionIndex],

        "checkConditionList",

        list

      );

      this.conditionForm.conditionList[this.conditionIndex].checkList =

        this.checkList;

      this.selectionCriteriaVisible = false;

    },

    changeCheckList(e) {

      // this.conditionList[this.conditionIndex].checkList = e;

    },

    //点击添加条件

    selectionCriteria(index) {

      this.conditionIndex = index;

      this.checkList = this.conditionForm.conditionList[index].checkList;

      this.selectionCriteriaVisible = true;

    },



    //点击添加条件组

    addConditionGroup() {

      if (this.conditionForm.conditionList.length >= 3) {

        this.$message.error("最多添加三个条件组");

      } else {

        this.conditionForm.conditionList.push({

          checkList: [],

        });

      }

    },

    // 删除条件组

    delCondition(index) {

      this.conditionForm.conditionList.splice(index, 1);
      if (this.conditionForm.conditionList.length > 0) {
        this.saveExpression('validateForm')
      } else {
        let data = "";
        this.flowConditionForm.body = data;
        this.updateFlowCondition();
        const attrObj = Object.create(null);
        attrObj["conditionList"] = JSON.stringify(
          this.conditionForm.conditionList
        );
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        );
        this.loading = false;
        this.$message.success("保存成功");
      }
    },

    resetFlowCondition() {

      // console.log(this.formInfo, "forminfo");

      this.bpmnElement = window.bpmnInstances.bpmnElement;

      this.bpmnElementSource = this.bpmnElement.source;

      this.bpmnElementSourceRef = this.bpmnElement.businessObject.sourceRef;

      this.elementBaseInfo = JSON.parse(

        JSON.stringify(this.bpmnElement.businessObject)

      );



      let { conditionList } = this.bpmnElement.businessObject.$attrs;

      this.conditionForm.conditionList = conditionList

        ? JSON.parse(conditionList)

        : [];



      if (

        this.bpmnElementSourceRef &&

        this.bpmnElementSourceRef.default &&

        this.bpmnElementSourceRef.default.id === this.bpmnElement.id

      ) {

        // 默认

        this.flowConditionForm = { type: "default" };

      } else if (!this.bpmnElement.businessObject.conditionExpression) {

        // 普通

        this.flowConditionForm = { type: "normal" };

      } else {

        // 带条件

        const conditionExpression =

          this.bpmnElement.businessObject.conditionExpression;

        this.flowConditionForm = { ...conditionExpression, type: "condition" };

        // resource 可直接标识 是否是外部资源脚本

        if (this.flowConditionForm.resource) {

          this.$set(this.flowConditionForm, "conditionType", "script");

          this.$set(this.flowConditionForm, "scriptType", "externalScript");

          return;

        }

        if (conditionExpression.language) {

          this.$set(this.flowConditionForm, "conditionType", "script");

          this.$set(this.flowConditionForm, "scriptType", "inlineScript");

          return;

        }

        this.$set(this.flowConditionForm, "conditionType", "expression");

      }

    },

    updateFlowType(flowType) {

      // 正常条件类

      if (flowType === "condition") {

        // console.log("正常条件类", this.flowConditionRef);

        this.flowConditionRef = window.bpmnInstances.moddle.create(

          "bpmn:FormalExpression"

        );

        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

          conditionExpression: this.flowConditionRef,

        });

        this.conditionForm.conditionList = [];

        const attrObj = Object.create(null);

        attrObj["conditionList"] = JSON.stringify(

          this.conditionForm.conditionList

        );

        window.bpmnInstances.modeling.updateProperties(

          this.bpmnElement,

          attrObj

        );

        return;

      }

      // 默认路径

      if (flowType === "default") {

        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

          conditionExpression: null,

        });

        window.bpmnInstances.modeling.updateProperties(this.bpmnElementSource, {

          default: this.bpmnElement,

        });

        this.conditionForm.conditionList = [];

        const attrObj = Object.create(null);

        attrObj["conditionList"] = JSON.stringify(

          this.conditionForm.conditionList

        );

        window.bpmnInstances.modeling.updateProperties(

          this.bpmnElement,

          attrObj

        );

        return;

      }

      // 正常路径，如果来源节点的默认路径是当前连线时，清除父元素的默认路径配置

      if (

        this.bpmnElementSourceRef.default &&

        this.bpmnElementSourceRef.default.id === this.bpmnElement.id

      ) {

        window.bpmnInstances.modeling.updateProperties(this.bpmnElementSource, {

          default: null,

        });

      }

      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

        conditionExpression: null,

      });

    },

    updateFlowCondition() {

      // 将body进行赋值

      // console.log("updateFlowCondition", this.flowConditionForm);

      let { conditionType, scriptType, body, resource, language } =

        this.flowConditionForm;

      let condition;

      // console.log(conditionType, "conditionType");

      // return;



      if (conditionType === "expression") {

        condition = window.bpmnInstances.moddle.create(

          "bpmn:FormalExpression",

          { body }

        );

      } else {

        if (scriptType === "inlineScript") {

          condition = window.bpmnInstances.moddle.create(

            "bpmn:FormalExpression",

            { body, language }

          );

          this.$set(this.flowConditionForm, "resource", "");

        } else {

          this.$set(this.flowConditionForm, "body", "");

          condition = window.bpmnInstances.moddle.create(

            "bpmn:FormalExpression",

            { resource, language }

          );

        }

      }

      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {

        conditionExpression: condition,

      });

    },

  },

  beforeDestroy() {

    this.bpmnElement = null;

    this.bpmnElementSource = null;

    this.bpmnElementSourceRef = null;

  },

};

</script>

<style scoped lang="scss">
.el-row {

  display: flex;

  align-items: center;

  margin-bottom: 14px;

}



::v-deep .el-form-item__content {

  margin-left: 0 !important;

}



// ::v-deep .el-form-item__error {

//   display: none !important;

// }</style>
