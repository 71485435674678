<template>
  <div class="panel-tab__content">
    <el-form size="mini" label-position="top" :model="model" :rules="rules" @submit.native.prevent>
      <div v-if="elementBaseInfo.$type === 'bpmn:Process'">
        <!-- 如果是 Process 信息的时候，使用自定义表单 -->
        <!-- <el-link
          href="https://doc.iocoder.cn/bpm/#_3-%E6%B5%81%E7%A8%8B%E5%9B%BE%E7%A4%BA%E4%BE%8B"
          type="danger"
          target="_blank"
          >如何实现实现会签、或签？</el-link
        > -->
        <el-form-item label="流程标识" prop="key">
          <el-input v-model="model.key" placeholder="请输入流标标识" readonly @change="handleKeyUpdate" />
        </el-form-item>
        <el-form-item label="流程名称" prop="name">
          <el-input v-model="model.name" placeholder="请输入流程名称" readonly clearable @change="handleNameUpdate" />
        </el-form-item>
      </div>
      <div v-else>
        <el-form-item label="ID">
          <el-input v-model="elementBaseInfo.id" clearable @change="updateBaseInfo('id')" />
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="elementBaseInfo.name" clearable @change="updateBaseInfo('name')" />
        </el-form-item>
        <!-- 抄送人员 -->
        <template v-if="elementBaseInfo.$type == 'bpmn:ServiceTask'">
          <el-form-item label="抄送人员" prop="userIds">
            <div class="select_box" style="margin-bottom: 8px">
              <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(98)">
                添加人员
              </el-tag>
              <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.user" :key="item.value"
                closable @close="closeUser(index, 98)">
                {{ item.label }}
              </el-tag>
            </div>
            <div class="select_box" style="margin-bottom: 8px">
              <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(97)">
                添加用户组
              </el-tag>
              <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.role" :key="item.value"
                closable @close="closeUser(index, 97)">
                {{ item.label }}
              </el-tag>
            </div>
            <div class="select_box">
              <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(96)">
                添加主管
              </el-tag>
              <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.dept" :key="item.value"
                closable @close="closeUser(index, 96)">
                {{ item.label }}
              </el-tag>
            </div>
          </el-form-item>
        </template>
        <template v-if="elementBaseInfo.$type == 'bpmn:UserTask'">
          <el-form-item label="指派类型">
            <el-select v-model="elementBaseInfo.type" clearable style="width: 100%" @change="handleUpdate('type')">
              <el-option v-for="dict in taskAssignRuleTypeDictDatas" :key="parseInt(dict.value)" :label="dict.label"
                :value="parseInt(dict.value)" :disabled="dict.value == 80 && cascaderFlag" />
            </el-select>
          </el-form-item>
          <!-- 指定成员 30-->
          <template v-if="elementBaseInfo.type === 30">
            <el-form-item label="指派人员" prop="userIds">
              <div class="select_box">
                <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(30)">
                  添加
                </el-tag>
                <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.userList"
                  :key="item.userId" closable @close="closeUser(index, 30)">
                  {{ item.label }}
                </el-tag>
              </div>
            </el-form-item>
          </template>
          <!-- 发起人自选 70-->
          <template v-if="elementBaseInfo.type === 70">
            <el-form-item label="发起人自选">
              <!-- 模式，0：自选一个；1：自选多个 -->
              <el-select v-model="elementBaseInfo.options.schema" style="width: 100%; margin-bottom: 8px"
                @change="handleUpdate('options')">
                <el-option v-for="item in $store.state.bpm.schemaOption" :key="parseInt(item.value)" :label="item.label"
                  :value="item.value" />
              </el-select>
              <!-- 人员范围 0：全公司；1：指定成员；2：用户组 -->
              <el-select v-model="elementBaseInfo.options.scope" style="width: 100%; margin-bottom: 8px"
                @change="changeScope">
                <el-option v-for="item in $store.state.bpm.scopeOption" :key="parseInt(item.value)" :label="item.label"
                  :value="item.value" />
              </el-select>
              <div class="select_box" v-if="elementBaseInfo.options.scope != 0">
                <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(70)">
                  添加
                </el-tag>
                <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.candidates"
                  :key="item.userId" closable @close="closeUser(index, 70)">
                  {{ item.label }}
                </el-tag>
              </div>
            </el-form-item>
          </template>
          <!-- 用户组 40 -->
          <template v-if="elementBaseInfo.type === 40">
            <el-form-item label="用户组">
              <div class="select_box">
                <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(40)">
                  添加
                </el-tag>
                <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.roles"
                  :key="item.userId" closable @close="closeUser(index, 40)">
                  {{ item.label }}
                </el-tag>
              </div>
            </el-form-item>
          </template>
          <!-- 直属主管 21-->
          <template v-if="elementBaseInfo.type === 21">
            <el-form-item label="发起人的">
              <el-select v-model="elementBaseInfo.options.level" style="width: 100%; margin-bottom: 8px"
                @change="handleUpdate('options')">
                <el-option v-for="item in levelOption" :key="parseInt(item.value)" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </template>
          <!-- 部门主管 22-->
          <template v-if="elementBaseInfo.type === 22">
            <el-form-item label="发起人的">
              <el-select v-model="elementBaseInfo.options.level" style="width: 100%; margin-bottom: 8px"
                @change="handleUpdate('options')">
                <el-option v-for="item in levelOption" :key="parseInt(item.value)" :label="item.label"
                  :value="item.value" />
              </el-select>
              <el-checkbox v-model="elementBaseInfo.options.alternate"
                @change="handleUpdate('options')">找不到主管时，由上级主管代审批</el-checkbox>
            </el-form-item>
          </template>
          <!-- 连续多级主管 23-->
          <template v-if="elementBaseInfo.type === 23">
            <el-form-item label="通讯录中的">
              <el-select v-model="elementBaseInfo.options.level" style="width: 100%; margin-bottom: 8px"
                @change="handleUpdate('options')">
                <el-option v-for="item in continuousOption" :key="parseInt(item.value)" :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </template>
          <!-- 表单内的联系人 80-->
          <template v-if="elementBaseInfo.type === 80">
            <el-form-item label="表单联系人">
              <el-select v-model="elementBaseInfo.options.contact" style="width: 100%; margin-bottom: 8px"
                @change="handleUpdate('options')">
                <el-option v-for="item in cascaderOption" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </template>
          <!-- 会签,或签 -->
          <el-form-item label="审批方式" v-if="isSingle">
            <el-radio-group v-model="elementBaseInfo.options.approveType" @change="changeApproveType">
              <el-radio label="sequence"><span
                  style="white-space: wrap;word-break: break-all;font-size: 12px;">依次审批(按顺序同意或拒绝)</span></el-radio>
              <el-radio label="and" v-if="elementBaseInfo.type != 23"><span
                  style="white-space: wrap;word-break: break-all;font-size: 12px;">会签(需要所有审批人都同意才可通过)</span></el-radio>
              <el-radio label="or" v-if="elementBaseInfo.type != 23"><span
                  style="white-space: wrap;word-break: break-all;font-size: 12px;">或签(其中一名审批人同意或拒绝即可)</span></el-radio>
            </el-radio-group>
          </el-form-item>
          <!-- 审批人为空时-->
          <template v-if="isReserved">
            <el-form-item label="审批人空时">
              <el-radio-group v-model="elementBaseInfo.options.reservedType" @change="handleUpdate('options')"
                style="margin-bottom: 8px">
                <el-radio label="pass">自动通过</el-radio>
                <el-radio label="transfer">自动转交管理员<el-tooltip class="item" placement="top">
                    <div slot="content">
                      <div style="font-size: 12px;background-color:#f3f4f6;padding: 2px 4px;border-radius: 4px;border: 1px solid #848484;">当前审批未找到审批人，转交给流程管理员</div>
                    </div>
                    <i class="el-icon-info"></i>
                  </el-tooltip></el-radio>
                <el-radio label="assign"> 指定人员审批</el-radio>
              </el-radio-group>
            </el-form-item>
            <div class="select_box" v-if="elementBaseInfo.options.reservedType == 'assign'">
              <el-tag style="cursor: pointer" type="info" size="mini" @click="addUser(99)">
                添加
              </el-tag>
              <el-tag type="info" size="mini" v-for="(item, index) in elementBaseInfo.options.reservedUser"
                :key="item.userId" closable @close="closeUser(index, 99)">
                {{ item.label }}
              </el-tag>
            </div>
          </template>

          <!-- 用户组 -->
          <el-form-item v-if="elementBaseInfo.type === 10" label="指派人员" prop="options">
            <el-select v-model="elementBaseInfo.options" multiple clearable style="width: 100%"
              @change="handleUpdate('options')">
              <el-option v-for="item in $store.state.bpm.roleOptions" :key="parseInt(item.roleId)"
                :label="item.roleName" :value="item.roleId" />
            </el-select>
          </el-form-item>
          <!-- 指定部门 -->
          <el-form-item v-if="false" label="指派人员" prop="deptIds">
            <treeselect v-model="elementBaseInfo.options" :options="$store.state.bpm.deptTreeOptions" multiple flat
              :defaultExpandLevel="3" placeholder="请选择" :normalizer="normalizer" @input="handleUpdate('options')" />
          </el-form-item>
          <!-- 指定用户 -->
          <el-form-item v-if="false" label="指派人员" prop="userIds">
            <el-select v-model="elementBaseInfo.options" multiple clearable style="width: 100%" filterable
              @change="handleUpdate('options')">
              <el-option v-for="item in $store.state.bpm.userOptions" :key="item.userId" :label="item.userName"
                :value="item.userId" />
            </el-select>
          </el-form-item>
          <!-- 指定用户组 -->
          <el-form-item v-if="false" label="指派人员" prop="userGroupIds">
            <el-select v-model="elementBaseInfo.options" multiple clearable style="width: 100%"
              @change="handleUpdate('options')">
              <el-option v-for="item in $store.state.bpm.userGroupOptions" :key="item.id" :label="item.name"
                :value="item.id" />
            </el-select>
          </el-form-item>
        </template>
      </div>
    </el-form>
    <initiatingRange :title="title" ref="initiatingRange" :isUser="true" :addType="addType" @confirm="confirm"
      :selectedList="selectedList" :radio="radio" />
  </div>
</template>
<script>
import { mapState } from "vuex";
import initiatingRange from "@/views/initiatingRange";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
// import { listSimpleRoles } from "@/api/system/role";
// import { listSimpleUsers } from "@/api/system/user";
// import { listSimpleDepts } from "@/api/system/dept";
// import { listSimpleUserGroups } from "@/api/bpm/userGroup";
import { DICT_TYPE, getDictDatas } from "@/utils/dict";
import { set } from "vue";
import { conforms } from "lodash-es";
export default {
  name: "ElementBaseInfo",
  components: {
    Treeselect,
    initiatingRange,
  },
  props: {
    id: String,
    businessObject: Object,
    model: {
      type: Object,
      default: {
        name: "",
      },
    }, // 流程模型的数据
  },
  inject: {
    prefix: "prefix",
    width: "width",
  },
  data() {
    return {
      cascaderFlag: false,
      cascaderOption: [],
      elementPropertyList: [],
      levelOption: [],
      continuousOption: [],
      listType: null,
      radio: false,
      addType: "0",
      title: "",
      selectedList: [],
      // 各种下拉框
      deptOptions: [],
      deptTreeOptions: [],
      postOptions: [],
      userOptions: [],

      // 数据字典
      taskAssignRuleTypeDictDatas: getDictDatas(
        DICT_TYPE.BPM_TASK_ASSIGN_RULE_TYPE
      ),
      elementBaseInfo: {
        options: {
          userList: [],
          approveType: "sequence",
          schema: 0,
          scope: 0,
          candidates: [],
          roles: [],
          reservedType: "transfer",
          reservedUser: [],
          level: 1,
          alternate: true,
          contact: null,
          role: [],
          user: [],
          dept: []
        },
      },
      sender: { role: [], user: [], dept: [] },
      options: {
        contact: null,
        userList: [],
        approveType: "sequence",
        schema: 0,
        scope: 0,
        candidates: [],
        roles: [],
        reservedType: "transfer",
        reservedUser: [],
        level: 1,
        alternate: true,
        role: [],
        user: [],
        dept: [],
      },
      // 流程表单的下拉框的数据
      forms: [],
      // 流程模型的校验
      rules: {
        key: [{ required: true, message: "流程标识不能为空", trigger: "blur" }],
        name: [
          { required: true, message: "流程名称不能为空", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选择规则类型", trigger: "blur" }],
        options: [{ required: true, message: "请选择", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState({
      formInfo: (state) => state.process.design.formInfo,
    }),
    // 审批人为空
    isReserved() {
      let { type } = this.elementBaseInfo;
      let { roles } = this.elementBaseInfo.options;
      if (type == 40 && roles.length) {
        return true;
      } else if (type == 21 || type == 22 || type == 23 || type == 80) {
        return true;
      } else {
        return false;
      }
    },
    // 审批方式
    isSingle() {
      let { type } = this.elementBaseInfo;
      let { userList, candidates, schema, scope, roles } =
        this.elementBaseInfo.options;
      //指定人员
      if (type == 30) {
        return userList.length > 1 ? true : false;
        //发起人自选
      } else if (type == 70) {
        if (schema == 0) {
          return false;
        } else {
          if (scope == 0) {
            return true;
          } else if (scope == 1) {
            return candidates.length > 1 ? true : false;
          } else if (scope == 2) {
            return true;
          }
        }
        //用户组
      } else if (type == 40) {
        return roles.length > 0 ? true : false;
        // 部门主管
      } else if (type == 22 || type == 23 || type == 80) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    "elementBaseInfo.type"(val) {
      if (val != 80) {
        this.$store.commit("bpm/SET_CONTACT", "");
      }
    },
    "elementBaseInfo.$type"(val) {
      if (val == 'bpmn:ServiceTask') {
        if (!this.elementBaseInfo.name) {
          this.elementBaseInfo.name = '抄送人'
          this.updateBaseInfo("name");
        }
        this.updateBaseInfo("name");
      }
    },
    "elementBaseInfo.options.contact"(val) {
      // console.log(val, "contact");
      this.$store.commit("bpm/SET_CONTACT", val);
    },
    // "model.name"(val) {
    //   this.handleNameUpdate(val);
    // },
    formInfo(val) {
      let arr = val.filter((e) => e.formType == "contacts");
      if (arr.length) {
        this.cascaderFlag = false;
        this.cascaderOption = arr.map((e) => {
          return {
            label: e.fieldName,
            value: e.fieldVal,
          };
        });
      } else {
        this.cascaderFlag = true;
        this.cascaderOption = [];
      }
    },
    isSingle(val) {
      let type = this.elementBaseInfo.$type;
      if (type == "bpmn:UserTask" || type == "bpmn:ServiceTask") {
        if (!val) {
          this.elementBaseInfo.options.approveType = null;
          this.changeApproval("null");
          this.updateBaseInfo("options");
        } else {
          if (this.elementBaseInfo.options.approveType) {
            this.changeApproval(this.elementBaseInfo.options.approveType);
            this.updateBaseInfo("options");
          } else {
            this.elementBaseInfo.options.approveType = "sequence";
            this.changeApproval(this.elementBaseInfo.options.approveType);
            this.updateBaseInfo("options");
          }
        }
      }
    },
    businessObject: {
      immediate: false,
      handler: function (val) {
        if (val) {
          this.$nextTick(() => this.resetBaseInfo());
        }
      },
    },
    model: {
      handler(val) {
        setTimeout(() => {
          if (val) {
            this.handleNameUpdate(val.name);
          }
        }, 100);
      },
      deep: true,
      immediate: false,
    },
    // 'model.key': {
    //   immediate: false,
    //   handler: function (val) {
    //     this.handleKeyUpdate(val)
    //   }
    // }
  },
  created() {
    this.getLevelOption();
    // 针对上传的 bpmn 流程图时，需要延迟 1 秒的时间，保证 key 和 name 的更新
    setTimeout(() => {
      this.handleKeyUpdate(this.model.key);
      this.handleNameUpdate(this.model.name);
    }, 1000);
  },
  methods: {
    resetAttributesList() {
      this.bpmnElement = window.bpmnInstances.bpmnElement;
      this.otherExtensionList = []; // 其他扩展配置
      this.bpmnElementProperties =
        this.bpmnElement.businessObject?.extensionElements?.values?.filter(
          (ex) => {
            if (ex.$type !== `${this.prefix}:Properties`) {
              this.otherExtensionList.push(ex);
            }
            return ex.$type === `${this.prefix}:Properties`;
          }
        ) ?? [];

      // 保存所有的 扩展属性字段
      this.bpmnElementPropertyList = this.bpmnElementProperties.reduce(
        (pre, current) => pre.concat(current.values),
        []
      );
      // 复制 显示
      this.elementPropertyList = JSON.parse(
        JSON.stringify(this.bpmnElementPropertyList ?? [])
      );

      // 获取抄送人扩展属性
      let sender = this.elementPropertyList.filter((e) => e.name == "sender");
      if (sender.length) {
        this.sender = JSON.parse(sender[0].value);
      }

      // this.sender = JSON.parse(this.elementPropertyList[0].value);
      // console.log(this.elementPropertyList, "this.elementPropertyList");
      // console.log(this.sender, "propertyForm");
    },
    updateElementExtensions(properties) {
      const extensions = window.bpmnInstances.moddle.create(
        "bpmn:ExtensionElements",
        {
          values: this.otherExtensionList.concat([properties]),
        }
      );
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        extensionElements: extensions,
      });
    },
    getLevelOption() {
      this.levelOption = [...Array(20)].map((e, index) => {
        return {
          value: index + 1,
          label: `${index == 0 ? "直属" : "第" + (index + 1) + "级"}主管`,
        };
      });

      this.continuousOption = [...Array(20)].map((e, index) => {
        return {
          value: index + 1,
          label: `${index == 0 ? "最高" : "第" + (index + 1) + "个"}层级主管`,
        };
      });
    },
    changeApproveType(e) {
      if (this.elementBaseInfo.$type == "bpmn:UserTask") {
        this.changeApproval(e);
        this.updateBaseInfo("options");
      }
    },
    changeScope() {
      this.elementBaseInfo.options.candidates = [];
      this.handleUpdate("options");
    },
    closeUser(index, type) {
      if (type == 30) {
        this.elementBaseInfo.options.userList.splice(index, 1);
        this.updateBaseInfo("options");
      }
      if (type == 70) {
        this.elementBaseInfo.options.candidates.splice(index, 1);
        this.updateBaseInfo("options");
      }
      if (type == 40) {
        this.elementBaseInfo.options.roles.splice(index, 1);
        this.elementBaseInfo.name = ''
        this.updateBaseInfo("options");
        this.updateBaseInfo("name");
      }
      if (type == 96) {
        this.elementBaseInfo.options.dept.splice(index, 1);
        this.updateBaseInfo("options");
      }
      if (type == 97) {
        this.elementBaseInfo.options.role.splice(index, 1);
        this.updateBaseInfo("options");
      }
      if (type == 98) {
        this.elementBaseInfo.options.user.splice(index, 1);
        this.updateBaseInfo("options");
      }
      if (type == 99) {
        this.elementBaseInfo.options.reservedUser.splice(index, 1);
        this.updateBaseInfo("options");
      }
    },
    addUser(type) {
      this.listType = type;
      this.selectedList = [];
      let { userList, user, candidates, scope, roles, role, reservedUser, dept } =
        this.elementBaseInfo.options;
      // 抄送人选主管
      if (type == 96) {
        this.title = "抄送主管";
        this.addType = "4";
        this.radio = true;
        // if (this.sender && this.sender.role.length)
        if (dept) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              dept.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "manager",
                };
              })
            )
          );
        }
      }
      // 抄送人选用户组
      if (type == 97) {
        this.title = "抄送人选用户组";
        this.addType = "1";
        this.radio = false;
        // if (this.sender && this.sender.role.length)
        if (role) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              role.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "group",
                };
              })
            )
          );
        }
      }
      // 抄送人选择人员
      if (type == 98) {
        this.title = "抄送人选择人员";
        this.addType = "0";
        this.radio = false;
        // if (this.sender && this.sender.user.length)
        if (user) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              user.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "uid",
                };
              })
            )
          );
        }
        // console.log(this.selectedList);
      }
      // 指定人员
      if (type == 30) {
        this.title = "指定人员";
        this.addType = "0";
        this.radio = false;
        if (userList.length) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              userList.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "uid",
                };
              })
            )
          );
        }
      }
      // 发起人自选
      if (type == 70) {
        this.title = "发起人自选";
        let type = "";
        if (scope == 1) {
          this.addType = "0";
          this.radio = false;
          type = "uid";
        } else if (scope == 2) {
          this.addType = "1";
          this.radio = true;
          type = "group";
        }
        // console.log(candidates, "candidates");
        if (candidates.length) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              candidates.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type,
                };
              })
            )
          );
        }
      }
      //用户组
      if (type == 40) {
        this.title = "用户组";
        this.addType = "1";
        this.radio = true;
        if (roles.length) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              roles.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "group",
                };
              })
            )
          );
        }
      }
      // 指定人员审批
      if (type == 99) {
        this.title = "指定人员审批";
        this.addType = "0";
        this.radio = true;
        if (reservedUser.length) {
          this.selectedList = JSON.parse(
            JSON.stringify(
              reservedUser.map((e) => {
                return {
                  id: e.value,
                  name: e.label,
                  type: "uid",
                };
              })
            )
          );
        }
      }
      this.$refs.initiatingRange.dialogVisible = true;
    },
    confirm(e) {
      let { listType } = this;
      let userList = e.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      if (listType == 30) {
        this.elementBaseInfo.options.userList = userList;
        this.handleUpdate("options");
      }
      if (listType == 70) {
        this.elementBaseInfo.options.candidates = userList;
        this.handleUpdate("options");
      }
      if (listType == 40) {
        this.elementBaseInfo.options.roles = userList;
        this.handleUpdate("options");
        this.elementBaseInfo.name = userList[0].label
        this.updateBaseInfo("name");
      }
      if (listType == 99) {
        this.elementBaseInfo.options.reservedUser = userList;
        this.handleUpdate("options");
      }

      if (listType == 98) {
        this.elementBaseInfo.options.user = userList;
        this.handleUpdate("options");
      }
      if (listType == 97) {
        this.elementBaseInfo.options.role = userList;
        this.handleUpdate("options");
      }
      if (listType == 96) {
        this.elementBaseInfo.options.dept = userList;
        this.handleUpdate("options");
      }
    },

    normalizer(node) {
      return {
        id: node.deptId,
        label: node.deptName,
        children: node.children,
      };
    },
    resetBaseInfo() {
      this.bpmnElement = window?.bpmnInstances?.bpmnElement;
      this.elementBaseInfo = JSON.parse(
        JSON.stringify(this.bpmnElement.businessObject)
      );

      let { type, options, flowable } = this.bpmnElement.businessObject.$attrs;
      if (type) {
        type = type * 1;
      }
      if (!options) {
        options = this.options;
      } else {
        options = JSON.parse(options);
      }

      if (this.elementBaseInfo.$type == "bpmn:ServiceTask" && !flowable) {
        this.updateBaseInfo("flowable");
      }
      this.elementBaseInfo = {
        ...this.elementBaseInfo,
        type,
        options,
      };
      this.resetAttributesList();
      // console.log(this.elementBaseInfo, 123);
    },

    handleUpdate(value) {
      if (value == "type") {
        console.log(`output->this.elementBaseInfo.type`, this.elementBaseInfo.type)
        if (this.elementBaseInfo.type == 30) {
          this.elementBaseInfo.name = '审批人'
          this.updateBaseInfo("name");
        } else if (this.elementBaseInfo.type == 60) {
          this.elementBaseInfo.name = '发起人自己'
          this.updateBaseInfo("name");
        } else if (this.elementBaseInfo.type == 70) {
          this.elementBaseInfo.name = '发起人自选'
          this.updateBaseInfo("name");
        } else if (this.elementBaseInfo.type == 80) {
          this.elementBaseInfo.name = '联系人'
          this.updateBaseInfo("name");
        } else {
          this.elementBaseInfo.name = ''
          this.updateBaseInfo("name");
        }
        this.elementBaseInfo.options = this.options;
        this.updateBaseInfo("options");
      }
      // this.elementBaseInfo[value] = this.elementBaseInfo[value];
      this.updateBaseInfo(value);
      if (this.elementBaseInfo.type == 21) {
        this.elementBaseInfo.name = '汇报线直属主管'
        if (this.elementBaseInfo.options.level != 1) {
          this.elementBaseInfo.name = '汇报线第' + this.elementBaseInfo.options.level + '级主管'
        }
        this.updateBaseInfo("name");
      }
      if (this.elementBaseInfo.type == 22) {
        this.elementBaseInfo.name = '直接主管'
        if (this.elementBaseInfo.options.level != 1) {
          this.elementBaseInfo.name = '第' + this.elementBaseInfo.options.level + '级主管'
        }
        this.updateBaseInfo("name");
      }
      if (this.elementBaseInfo.type == 23) {
        this.elementBaseInfo.name = '直接主管到通讯录中级别最高的第' + this.elementBaseInfo.options.level + '个层级主管'
        this.updateBaseInfo("name");
      }
    },
    handleKeyUpdate(value) {
      // 校验 value 的值，只有 XML NCName 通过的情况下，才进行赋值。否则，会导致流程图报错，无法绘制的问题
      if (!value) {
        return;
      }
      if (!value.match(/[a-zA-Z_][\-_.0-9a-zA-Z$]*/)) {
        // console.log("key 不满足 XML NCName 规则，所以不进行赋值");
        return;
      }
      // console.log("key 满足 XML NCName 规则，所以进行赋值");
      // 在 BPMN 的 XML 中，流程标识 key，其实对应的是 id 节点
      // console.log("handleKeyUpdate");
      this.elementBaseInfo["id"] = value;
      this.updateBaseInfo("id");
    },
    handleNameUpdate(value) {
      if (!value) {
        return;
      }
      // console.log("handleNameUpdate");
      this.elementBaseInfo["name"] = value;
      this.updateBaseInfo("name");
    },
    handleDescriptionUpdate(value) {
      // TODO 芋艿：documentation 暂时无法修改，后续在看看
      // this.elementBaseInfo['documentation'] = value;
      // this.updateBaseInfo('documentation');
    },
    updateBaseInfo(key) {
      // console.log(key);
      // 触发 elementBaseInfo 对应的字段
      const attrObj = Object.create(null);

      if (key == "options") {
        attrObj[key] = JSON.stringify(this.elementBaseInfo[key]);
      } else if (key == "flowable") {
        attrObj["flowable:class"] =
          "com.hf.workflow.Utils.listener.SendMsgDelegate";
        attrObj["flowable"] = true;
      } else {
        attrObj[key] = this.elementBaseInfo[key]; 
      }
      // attrObj[key] = this.elementBaseInfo[key];
      if (key === "id") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          id: this.elementBaseInfo[key],
          di: { id: `${this.elementBaseInfo[key]}_di` },
        });
      } else {
        window.bpmnInstances.modeling.updateProperties(
          this.bpmnElement,
          attrObj
        );
      }
    },
    changeApproval(e) {
      let a = ''
      if (e == "sequence") {
        // this.loopCharacteristics = "SequentialMultiInstance";
        this.changeLoopCharacteristicsType("SequentialMultiInstance");
        this.updateLoopCondition("${nrOfCompletedInstances==nrOfInstances}");
        this.updateLoopCardinality("1");
        a = '_依次审批'
      } else if (e == "and") {
        // this.loopCharacteristics = "ParallelMultiInstance";
        this.changeLoopCharacteristicsType("ParallelMultiInstance");
        this.updateLoopCondition("${nrOfCompletedInstances==nrOfInstances}");
        a = '_会签'
      } else if (e == "or") {
        // this.loopCharacteristics = "ParallelMultiInstance";
        this.changeLoopCharacteristicsType("ParallelMultiInstance");
        this.updateLoopCondition("${nrOfCompletedInstances==1}");
        a = '_或签'
      } else {
        this.changeLoopCharacteristicsType("Null");
        // this.updateLoopCondition("");
      }
      // if (this.elementBaseInfo.name?.includes('_')) {
      //   const lastIndex = this.elementBaseInfo.name.lastIndexOf('_');
      //   this.elementBaseInfo.name = this.elementBaseInfo.name.substring(0, lastIndex) + a;
      //   this.updateBaseInfo("name");
      // }
    },
    // 完成条件
    updateLoopCondition(condition) {
      let completionCondition = null;
      if (condition && condition.length) {
        completionCondition = window.bpmnInstances.moddle.create(
          "bpmn:FormalExpression",
          { body: condition }
        );
      }
      window.bpmnInstances.modeling.updateModdleProperties(
        this.bpmnElement,
        this.multiLoopInstance,
        { completionCondition }
      );
    },
    // 循环基数
    updateLoopCardinality(cardinality) {
      let loopCardinality = null;
      if (cardinality && cardinality.length) {
        loopCardinality = window.bpmnInstances.moddle.create(
          "bpmn:FormalExpression",
          { body: cardinality }
        );
      }
      window.bpmnInstances.modeling.updateModdleProperties(
        this.bpmnElement,
        this.multiLoopInstance,
        { loopCardinality }
      );
    },
    changeLoopCharacteristicsType(type) {
      // this.loopInstanceForm = { ...this.defaultLoopInstanceForm }; // 切换类型取消原表单配置
      // 取消多实例配置
      if (type === "Null") {
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          loopCharacteristics: null,
        });
        return;
      }
      // 配置循环
      if (type === "StandardLoop") {
        const loopCharacteristicsObject = window.bpmnInstances.moddle.create(
          "bpmn:StandardLoopCharacteristics"
        );
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
          loopCharacteristics: loopCharacteristicsObject,
        });
        this.multiLoopInstance = null;
        return;
      }
      // 时序
      if (type === "SequentialMultiInstance") {
        this.multiLoopInstance = window.bpmnInstances.moddle.create(
          "bpmn:MultiInstanceLoopCharacteristics",
          { isSequential: true }
        );
      } else {
        this.multiLoopInstance = window.bpmnInstances.moddle.create(
          "bpmn:MultiInstanceLoopCharacteristics",
          { collection: "${coll_userList}" }
        );
      }
      window.bpmnInstances.modeling.updateProperties(this.bpmnElement, {
        loopCharacteristics: this.multiLoopInstance,
      });
    },
  },
  beforeDestroy() {
    this.bpmnElement = null;
  },

  // 格式化部门的下拉框
};
</script>
<style lang="scss" scoped>
.select_box {
  min-height: 28px;
  widows: 200%;
  border: 1px solid #dddfe5;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  width: 100%;

  gap: 8px;
  flex-wrap: wrap;
}

.select_box:hover {
  border: 1px solid #448ef7;
}
</style>
