var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "advanced_setup" },
    [
      _c("div", { staticClass: "left" }, [
        _c(
          "div",
          { staticClass: "setup_list" },
          _vm._l(_vm.setupList, function(item) {
            return _c(
              "div",
              {
                key: item.code,
                staticClass: "setup_item",
                class: _vm.setupCode == item.code ? "setup_active" : "",
                on: {
                  click: function($event) {
                    return _vm.chosseSetup(item.code)
                  }
                }
              },
              [_vm._v(" " + _vm._s(item.name) + " ")]
            )
          }),
          0
        )
      ]),
      _c(
        "div",
        { staticClass: "right" },
        [
          _c("p", [
            _vm._v(
              _vm._s(
                _vm.setupList.find(function(e) {
                  return e.code == _vm.setupCode
                }).name
              )
            )
          ]),
          _vm.setupCode == 1
            ? [
                _c(
                  "el-row",
                  _vm._l(_vm.deployList, function(item) {
                    return _c(
                      "el-col",
                      { key: item.name, attrs: { span: 8 } },
                      [
                        _c("div", { staticClass: "deploy_item" }, [
                          _c(
                            "div",
                            { staticClass: "top" },
                            [
                              _c("div", [
                                _c("img", {
                                  attrs: { src: item.icon, alt: "" }
                                }),
                                _c("span", [_vm._v(_vm._s(item.name))])
                              ]),
                              _c("el-switch", {
                                attrs: {
                                  "active-color": "#5669ec",
                                  "inactive-color": "#5669ec1a",
                                  "active-value": 1,
                                  "inactive-value": 0
                                },
                                model: {
                                  value: item.value,
                                  callback: function($$v) {
                                    _vm.$set(item, "value", $$v)
                                  },
                                  expression: "item.value"
                                }
                              })
                            ],
                            1
                          ),
                          _c("div", { staticClass: "bottom" }, [
                            _vm._v(_vm._s(item.text))
                          ])
                        ])
                      ]
                    )
                  }),
                  1
                )
              ]
            : _vm._e(),
          _vm.setupCode == 2
            ? [
                _c(
                  "div",
                  { staticClass: "control_limit" },
                  [
                    _c("p", [_vm._v("管理范围")]),
                    _c(
                      "div",
                      { staticClass: "list mb20" },
                      _vm._l(_vm.controlLimit, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.value,
                            staticClass: "item",
                            on: {
                              click: function($event) {
                                return _vm.chooseLimit(item.value)
                              }
                            }
                          },
                          [
                            _vm.authType == item.value
                              ? _c("img", {
                                  attrs: {
                                    src: require("@/assets/check4.png"),
                                    alt: ""
                                  }
                                })
                              : _c("img", {
                                  attrs: {
                                    src: require("@/assets/check3.png"),
                                    alt: ""
                                  }
                                }),
                            _c("div", [_vm._v(_vm._s(item.name))])
                          ]
                        )
                      }),
                      0
                    ),
                    _vm.authType == 5
                      ? [
                          _vm.customRange.uid.length ||
                          _vm.customRange.dept.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.openRange }
                                },
                                [
                                  _vm.customRange.dept.length
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.customRange.dept.length) +
                                            "个部门"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm.customRange.uid.length &&
                                  _vm.customRange.dept.length
                                    ? _c("span", [_vm._v(",")])
                                    : _vm._e(),
                                  _vm.customRange.uid.length
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.customRange.uid.length) +
                                            "个人员"
                                        )
                                      ])
                                    : _vm._e()
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.openRange }
                                },
                                [_vm._v("请选择")]
                              )
                        ]
                      : _vm._e()
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c("initiatingRange", {
        ref: "initiatingRange",
        attrs: { addType: "0", selectedList: _vm.chooseList },
        on: { confirm: _vm.getChooseList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }